/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { parseGet, parsePost, parsePostMultipart, parsePut, voidDelete } from '@/utils/apiClient';
import { featureFetch } from '@/utils/featureFlags/featureFlags';
import { FF_AEC_TEST_DEPLOYMENT } from '@/utils/featureFlags/featureNames';
import {
  AecDeployment,
  AecRolloutStatus,
  AecTestDeploymentCreateRequest,
  AecTestDeploymentLogResultModel,
  AecVehicleCount,
  AecVehicleFilter,
  DmDeployment,
  VehicleIds,
  VehicleIdValidation,
  VehicleIdValidationRes,
} from '@/modules/campaign_management/models/Aec/AecDeploymentModels';
import { TestDeploymentStrategy } from '@/modules/campaign_management/models/Aec/AecModels';

const basePathTest = '/api/ae-container/test-deployments';
const featureGuard = featureFetch(FF_AEC_TEST_DEPLOYMENT);

const _createTestDeployment = (payload: AecTestDeploymentCreateRequest) =>
  parsePost(AecDeployment, `${basePathTest}`, payload);

const _getDeployment = (id: string) => parseGet(AecDeployment, `${basePathTest}/${id}`);

const _getVehicleIds = (id: string) => parseGet(VehicleIds, `${basePathTest}/${id}/vehicles/ids`);

const _getDeployments = (releaseCandidateId: string) =>
  parseGet(AecDeployment.array(), `/api/ae-container/release-candidates/${releaseCandidateId}/test-deployments`);

const _performTestDeploymentStrategy = async (testDeploymentId: string, strategy: TestDeploymentStrategy) =>
  parsePost(AecDeployment, `/api/ae-container/test-deployments/${testDeploymentId}/${strategy}`);

const _getAllTestDeployments = () => parseGet(DmDeployment.array(), `/api/ae-container/test-deployments`);

const _fetchVehicleCount = (deploymentId: string, payload: AecVehicleFilter) =>
  parsePost(AecVehicleCount, `${basePathTest}/${deploymentId}/vehicles/count`, payload);

const _getVehicleCount = (deploymentId: string) =>
  parseGet(AecVehicleCount, `${basePathTest}/${deploymentId}/vehicles/count`);

const _getFilterCriteria = (deploymentId: string) =>
  parseGet(AecVehicleFilter, `${basePathTest}/${deploymentId}/vehicles/filter-criteria`);

const _vehicleIdValidation = (deploymentId: string, payload: VehicleIdValidation) =>
  parsePut(VehicleIdValidationRes, `${basePathTest}/${deploymentId}/vehicles/approved`, payload);

const _updateCountAndFilterCriteria = (deploymentId: string, payload: AecVehicleFilter) =>
  parsePut(AecVehicleFilter, `${basePathTest}/${deploymentId}/vehicles/filter-criteria`, payload);

const _getRolloutStatus = (deploymentId: string) =>
  parseGet(AecRolloutStatus, `${basePathTest}/${deploymentId}/rollout/status`);

const _getLogResult = (deploymentId: string) =>
  parseGet(AecTestDeploymentLogResultModel, `${basePathTest}/${deploymentId}/log`);

const _saveLogResult = (
  deploymentId: string,
  payload: AecTestDeploymentLogResultModel,
  updateCallback?: (p: number) => void,
) =>
  parsePostMultipart(
    AecTestDeploymentLogResultModel,
    `${basePathTest}/${deploymentId}/log/saveLogResults`,
    payload,
    updateCallback,
  );

const _removeFileLogResult = (fileId: string) => voidDelete(`${basePathTest}/file/${fileId}`);

const _removeLinkLogResult = (linkId: string) => voidDelete(`${basePathTest}/links/${linkId}`);

export const filePathLogResult = (fileId: string) => `${basePathTest}/file/${fileId}`;
export const createTestDeployment = featureGuard(_createTestDeployment);
export const getDeployment = featureGuard(_getDeployment);
export const getVehicleIds = featureGuard(_getVehicleIds);
export const getDeployments = featureGuard(_getDeployments);
export const performTestDeploymentStrategy = featureGuard(_performTestDeploymentStrategy);
export const getAllTestDeployments = featureGuard(_getAllTestDeployments);
export const fetchVehicleCount = featureGuard(_fetchVehicleCount);
export const getVehicleCount = featureGuard(_getVehicleCount);
export const getFilterCriteria = featureGuard(_getFilterCriteria);
export const updateCountAndFilterCriteria = featureGuard(_updateCountAndFilterCriteria);
export const getTestRolloutStatus = featureGuard(_getRolloutStatus);
export const getTestLogResult = featureGuard(_getLogResult);
export const postLogResult = featureGuard(_saveLogResult);
export const removeFileLogResult = featureGuard(_removeFileLogResult);
export const removeLinkLogResult = featureGuard(_removeLinkLogResult);
export const vehicleIdValidation = featureGuard(_vehicleIdValidation);
