/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';
import { ZodDeprecatedRightEnum } from '@/models/user/DeprecatedRightEnum';

// TODO: implement the changes described in story #230478, when the migration to new Roles Concept has been finished.

export enum InternalRoleEnum {
  // brands
  BRAND_VW = 'BRAND_VW',
  BRAND_PORSCHE = 'BRAND_PORSCHE',
  BRAND_SKODA = 'BRAND_SKODA',
  BRAND_SEAT = 'BRAND_SEAT',
  BRAND_CUPRA = 'BRAND_CUPRA',
  BRAND_VWN = 'BRAND_VWN',
  BRAND_AUDI = 'BRAND_AUDI',
  BRAND_MAN = 'BRAND_MAN',

  // joint ventures
  JV_SAIC = 'JV_SAIC',
  JV_FAW = 'JV_FAW',
  JV_VWA = 'JV_VWA',

  // regions
  RGN_CANADA = 'RGN_CANADA',
  RGN_NORTH_AMERICA = 'RGN_NORTH_AMERICA',
  RGN_EUROPE = 'RGN_EUROPE',
  RGN_CHINA = 'RGN_CHINA',

  // update technologies
  UT_CU = 'UT_CU',
  UT_WEBAPP = 'UT_WEBAPP',
  UT_ORU_MEB = 'UT_ORU_MEB',
  UT_ORU_MEB_UNECE = 'UT_ORU_MEB_UNECE',
  UT_ORU_2_3 = 'UT_ORU_2_3',
  UT_ORU_NEXT = 'UT_ORU_NEXT',
  UT_DRM = 'UT_DRM',
  UT_ORU_2_0 = 'UT_ORU_2_0',
  UT_CMT = 'UT_CMT',
  UT_OI_NavDB = 'UT_OI_NavDB',
  UT_MIB2 = 'UT_MIB2',
  UT_MIB2_PLUS = 'UT_MIB2+',
  UT_MIB3_TI = 'UT_MIB3_TI',
  UT_CariadMap = 'UT_CariadMap',
  UT_AutoEdge_Container = 'UT_AutoEdge_Container',

  // vehicle platforms
  VP_MQB_MLB = 'VP_MQB_MLB',
  VP_E311 = 'VP_E311',
  VP_E312 = 'VP_E312',
  VP_E320 = 'VP_E320',

  // job titles
  DATA_LOAD_MANAGER = 'Data Load Manager',
  DATA_LOAD_ADMIN = 'Data Load Admin',
  RELEASE_MANAGER = 'Release Manager',
  AFTERSALES_MANAGER = 'Aftersales Manager',
  ANALYST = 'Analyst',
  CONTROL_CENTER_USER_MANAGER = 'Control Center User Manager',
  DRM_MANAGER = 'DRM Manager',
  VEHICLE_LIST_MANAGER = 'Vehicle List Manager',
  AEC_SDT_ADMIN = 'AEC_SDT_ADMIN',
  ORU_2_0_MANAGER = 'ORU 2.0 Manager',
  CMT_FUNCTION_OWNER = 'CMT Function Owner',
  CMT_DEPLOYMENT_MANAGER = 'CMT Deployment Manager',
  CAMPAIGN_MANAGER = 'Campaign Manager',
  CONTAINER_PO = 'Container PO',
  LAUNCH_OPERATOR = 'Launch Operator',
  TEST_MANAGER = 'Test Manager',
  SUPERSET_ADMIN = 'Superset Admin',
  TEST_VEHICLE_MANAGER = 'Test Vehicle Manager',
  DEPLOYMENT_DATA_VIEWER = 'Deployment Data Viewer',
  DEPLOYMENT_DATA_PROVIDER = 'Deployment Data Provider',
  BASELINE_UPDATE_MANAGER = 'Baseline Update Manager',
  BRAND_RELEASE_MANAGER_CUPRA = 'Brand Release Manager CUPRA',
  BRAND_RELEASE_MANAGER_PORSCHE = 'Brand Release Manager PORSCHE',
  BRAND_RELEASE_MANAGER_SKODA = 'Brand Release Manager SKODA',
  BRAND_RELEASE_MANAGER_VW = 'Brand Release Manager VW',
  BRAND_RELEASE_MANAGER_VWN = 'Brand Release Manager VWN',
  BRAND_RELEASE_MANAGER_AUDI = 'Brand Release Manager AUDI',

  PERM_DEPLOYMENT_DATA_WRITE = 'PERM_DEPLOYMENT_DATA_WRITE',
  ORU23_DATA_PROVIDER = 'ORU23_DATA_PROVIDER',

  GLOBAL_CM_E3_2_0_ORU_MANAGER = 'GLOBAL_CM_E3_2_0_ORU_MANAGER',
  CM_TEST_VIN_MANAGER = 'CM_TEST_VIN_MANAGER',

  PERM_DATALOAD_READ = 'PERM_DATALOAD_READ',
  PERM_DATALOAD_WRITE = 'PERM_DATALOAD_WRITE',
  PERM_SMART_CAMPAIGN_WRITE = 'PERM_SMART_CAMPAIGN_WRITE',
  PERM_SMART_CAMPAIGN_READ = 'PERM_SMART_CAMPAIGN_READ',
  PERM_SMART_CAMPAIGN_ROLLOUT = 'PERM_SMART_CAMPAIGN_ROLLOUT',
  PERM_SW_PROJECT_READ = 'PERM_SW_PROJECT_READ',
  PERM_SW_PROJECT_WRITE = 'PERM_SW_PROJECT_WRITE',
  PERM_SW_PROJECT_DELETE = 'PERM_SW_PROJECT_DELETE',
  PERM_RELEASE_WRITE = 'PERM_RELEASE_WRITE',
  PERM_RELEASE_CANDIDATE_PROMOTE_TO_APPROVAL = 'PERM_RELEASE_CANDIDATE_PROMOTE_TO_APPROVAL',
  PERM_RELEASE_CANDIDATE_PROMOTE_TO_LIVE = 'PERM_RELEASE_CANDIDATE_PROMOTE_TO_LIVE',
  PERM_DEPLOYMENT_READ = 'PERM_DEPLOYMENT_READ',
  PERM_DEPLOYMENT_WRITE = 'PERM_DEPLOYMENT_WRITE',
  PERM_DOCUMENT_READ = 'PERM_DOCUMENT_READ',
  PERM_DOCUMENT_UPLOAD = 'PERM_DOCUMENT_UPLOAD',

  PERM_TEST_VEHICLE_READ = 'PERM_TEST_VEHICLE_READ',

  PERM_IMPORTED_VEHICLE_LIST_WRITE = 'PERM_IMPORTED_VEHICLE_LIST_WRITE',
  PERM_STANDALONE_VEHICLE_LIST_WRITE = 'PERM_STANDALONE_VEHICLE_LIST_WRITE',
  PERM_IMPORTED_VEHICLE_LIST_READ = 'PERM_IMPORTED_VEHICLE_LIST_READ',
  PERM_STANDALONE_VEHICLE_LIST_READ = 'PERM_STANDALONE_VEHICLE_LIST_READ',

  PERM_DEPLOYMENT_TRACKING_READ = 'PERM_DEPLOYMENT_TRACKING_READ',
  PERM_ERROR_TRACKING_READ = 'PERM_ERROR_TRACKING_READ',

  PERM_BASELINE_READ = 'PERM_BASELINE_READ',
  PERM_BASELINE_WRITE = 'PERM_BASELINE_WRITE',

  PERM_TEST_DEPLOYMENT_READ = 'PERM_TEST_DEPLOYMENT_READ',
  PERM_TEST_DEPLOYMENT_WRITE = 'PERM_TEST_DEPLOYMENT_WRITE',
  PERM_TEST_DOCUMENT_WRITE = 'PERM_TEST_DOCUMENT_WRITE',
  PERM_TEST_DEPLOYMENT_WRITE_LIVE = 'PERM_TEST_DEPLOYMENT_WRITE_LIVE',
  PERM_SUMO_BRAND_APPROVAL = 'PERM_SUMO_BRAND_APPROVAL',
  PERM_TEST_DEPLOYMENT_ROLLOUT = 'PERM_TEST_DEPLOYMENT_ROLLOUT',
  PERM_DEPLOYMENT_VEHICLE_COUNT = 'PERM_DEPLOYMENT_VEHICLE_COUNT',
  PERM_TEST_DEPLOYMENT_REQUEST_READ_OWN = 'PERM_TEST_DEPLOYMENT_REQUEST_READ_OWN',
  PERM_TEST_DEPLOYMENT_REQUEST_READ_ACTIVE = 'PERM_TEST_DEPLOYMENT_REQUEST_READ_ACTIVE',
  PERM_TEST_DEPLOYMENT_REQUEST_WRITE_OWN = 'PERM_TEST_DEPLOYMENT_REQUEST_WRITE_OWN',
  PERM_TEST_DEPLOYMENT_REQUEST_AUDIT = 'PERM_TEST_DEPLOYMENT_REQUEST_AUDIT',
  PERM_TEST_DEPLOYMENT_REQUEST_DELETE = 'PERM_TEST_DEPLOYMENT_REQUEST_DELETE',

  PERM_DRMD_WRITE = 'PERM_DRMD_WRITE',

  PERM_DEPLOYMENT_ROLLOUT = 'PERM_DEPLOYMENT_ROLLOUT',

  // general access to CC indicator role
  VWAG_DLCMCC_DLCM_USER = 'VWAG_DLCMCC_DLCM-USER',

  // Superset roles
  ADMIN = 'Admin',

  GLOBAL_PROMOTE_USER_TO_SUPERADMIN = 'GLOBAL_PROMOTE_USER_TO_SUPERADMIN',
  USER_DEFINITION_KEY_USER_ADMIN = 'USER_DEFINITION_KEY_user-admin',

  //keycloak realm roles
  KC_CREATE_REALM = 'create-realm',
  KC_DEFAULT_ROLES_MASTER = 'default-roles-master',
  KC_OFFLINE_ACCESS = 'offline_access',
  KC_UMA_AUTHORIZATION = 'uma_authorization',

  //keycloak client account roles
  KC_REALM_ADMIN = 'realm-admin',
  KC_DELETE_ACCOUNT = 'delete-account',
  KC_MANAGE_ACCOUNT = 'manage-account',
  KC_MANAGE_ACCOUNT_LINKS = 'manage-account-links',
  KC_MANAGE_CONSENT = 'manage-consent',
  KC_VIEW_APPLICATIONS = 'view-applications',
  KC_VIEW_CONSENT = 'view-consent',
  KC_VIEW_PROFILE = 'view-profile',

  //keycloak client broker roles
  READ_TOKEN = 'read-token',

  //keycloak client master-realm roles
  KC_CREATE_CLIENT = 'create-client',
  KC_IMPERSONATION = 'impersonation',
  KC_MANAGE_AUTHORIZATION = 'manage-authorization',
  KC_MANAGE_CLIENTS = 'manage-clients',
  KC_MANAGE_EVENTS = 'manage-events',
  KC_MANAGE_IDENTITY_PROVIDERS = 'manage-identity-providers',
  KC_MANAGE_REALM = 'manage-realm',
  KC_MANAGE_USERS = 'manage-users',
  KC_QUERY_CLIENTS = 'query-clients',
  KC_QUERY_GROUPS = 'query-groups',
  KC_QUERY_REALMS = 'query-realms',
  KC_QUERY_USERS = 'query-users',
  KC_VIEW_AUTHORIZATION = 'view-authorization',
  KC_VIEW_CLIENTS = 'view-clients',
  KC_VIEW_EVENTS = 'view-events',
  KC_VIEW_IDENTITY_PROVIDERS = 'view-identity-providers',
  KC_VIEW_REALM = 'view-realm',
  KC_VIEW_USERS = 'view-users',
  KC_USER = 'user',

  //keycloak cc realm default roles
  KC_DEFAULT_ROLES_CC = 'default-roles-cc',
  KC_DEFAULT_ROLE_CC = 'CC_DEFAULT_ROLE',
}

export const ZodRoleEnum = z.nativeEnum(InternalRoleEnum);

export const RoleEnum = { ...ZodDeprecatedRightEnum.enum, ...InternalRoleEnum };
export type RoleEnum = InternalRoleEnum | z.infer<typeof ZodDeprecatedRightEnum>;

// Roles which do not display any pages in the control center UI
export const ROLES_WITHOUT_ANY_DIRECT_PERMISSION = [
  RoleEnum.VWAG_DLCMCC_DLCM_USER, // default role for all users on live
  RoleEnum.KC_DEFAULT_ROLES_CC,
  RoleEnum.KC_CREATE_REALM,
  RoleEnum.KC_DEFAULT_ROLES_MASTER,
  RoleEnum.KC_OFFLINE_ACCESS,
  RoleEnum.KC_UMA_AUTHORIZATION,
  RoleEnum.KC_REALM_ADMIN,
  RoleEnum.KC_DELETE_ACCOUNT,
  RoleEnum.KC_MANAGE_ACCOUNT,
  RoleEnum.KC_MANAGE_ACCOUNT_LINKS,
  RoleEnum.KC_VIEW_APPLICATIONS,
  RoleEnum.KC_VIEW_CONSENT,
  RoleEnum.KC_VIEW_PROFILE,
  RoleEnum.READ_TOKEN,
  RoleEnum.KC_CREATE_CLIENT,
  RoleEnum.KC_IMPERSONATION,
  RoleEnum.KC_MANAGE_AUTHORIZATION,
  RoleEnum.KC_MANAGE_CLIENTS,
  RoleEnum.KC_MANAGE_EVENTS,
  RoleEnum.KC_MANAGE_IDENTITY_PROVIDERS,
  RoleEnum.KC_MANAGE_REALM,
  RoleEnum.KC_MANAGE_USERS,
  RoleEnum.KC_QUERY_CLIENTS,
  RoleEnum.KC_QUERY_GROUPS,
  RoleEnum.KC_QUERY_REALMS,
  RoleEnum.KC_QUERY_USERS,
  RoleEnum.KC_VIEW_AUTHORIZATION,
  RoleEnum.KC_VIEW_CLIENTS,
  RoleEnum.KC_VIEW_EVENTS,
  RoleEnum.KC_VIEW_IDENTITY_PROVIDERS,
  RoleEnum.KC_VIEW_REALM,
  RoleEnum.KC_VIEW_USERS,
  RoleEnum.KC_USER,
];
