/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { defineAsyncComponent } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import * as types from '@route-types';
import { DeprecatedRightEnum } from '@/models/user/DeprecatedRightEnum';
import { RoleEnum } from '@/models/user/RoleEnum';
import { hasRole, hasRoles } from '@/models/user/User';
import { isDeploymentStage, useAuthStore } from '@/stores/auth';
import { featureGate } from '@/utils/featureFlags/featureFlags';
import { FF_AEC_LIVE_DEPLOYMENT, FF_AEC_TEST_DEPLOYMENT, FF_DATA_UNECE } from '@/utils/featureFlags/featureNames';
import { EntityType } from '@/modules/campaign_management/models/VinListFilter/VinListModels';
import { hasCommonCampaignRoles } from '@/modules/campaign_management/states/commonCampaignState';
import { hasCommonDmTestDeploymentsRoles } from '@/modules/campaign_management/states/commonDeploymentState';
import { hasCommonSwProjectRoles } from '@/modules/campaign_management/states/commonSwProjectState';

const Plain = defineAsyncComponent(() => import('@/layouts/Plain'));
const DefaultLayout = defineAsyncComponent(() => import('@/layouts/DefaultLayout'));

export type RouteType = keyof typeof types;

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/error',
    name: types.ERROR,
    component: () => import('@/views/ErrorView'),
    meta: {
      layout: Plain,
    },
  },
  {
    path: '/login',
    name: types.LOGIN,
    component: () => import('@/views/Login'),
    meta: {
      layout: Plain,
    },
  },
  {
    path: '/logout',
    name: types.LOGOUT,
    component: () => import('@/views/Logout'),
    meta: {
      layout: Plain,
    },
  },
  {
    path: '/profile',
    name: types.PROFILE,
    component: () => import('@/views/Profile'),
    props: true,
  },
  {
    path: '/release-notes',
    name: types.RELEASE_NOTES,
    component: () => import('@/views/ReleaseNotes'),
  },
  {
    path: '/legal-notice',
    name: types.LEGAL_NOTICE,
    component: () => import('@/views/LegalNotice'),
  },
  {
    path: '/help',
    name: types.HELP,
    component: () => import('@/views/Help'),
  },
  /**
   * MAIN NAVIGATION ITEMS
   */
  {
    path: '/',
    name: types.DASHBOARD,
    component: () => import('@/views/Dashboard'),
    meta: {
      isTopNavigation: true,
      isMainNavigation: true,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faBorderAll')).faBorderAll,
    },
  },
  {
    path: '/software/projects',
    name: types.CM_RELEASES,
    component: () => import('@/modules/campaign_management/views/Releases/SwProjectOverview'),
    meta: {
      roles: [hasCommonSwProjectRoles],
      parent: types.CAMPAIGN_MANAGEMENT,
      isSubNavigation: true,
      isMainNavigation: true,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faSitemap')).faSitemap,
    },
  },
  {
    path: '/software/dlcm-gate/baseline-update',
    name: types.DLCM_GATE_BLU_LIST,
    component: () => import('@/modules/dlcm_gate/views/BluList'),
    meta: {
      roles: [RoleEnum.PERM_BASELINE_READ, RoleEnum.VP_E312, RoleEnum.UT_ORU_NEXT],
      parent: types.CAMPAIGN_MANAGEMENT,
      isSubNavigation: true,
      isMainNavigation: true,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faLayerGroup')).faLayerGroup,
    },
  },
  {
    path: '/software/drmd',
    name: types.DLCM_RELEASE_MANAGEMENT_DASHBOARD,
    redirect: { name: types.DRMD_MILESTONE },
    component: () => import('@/modules/drmd_app/views/DRMDApp'),
    // props: true,
    meta: {
      roles: [RoleEnum.PERM_DRMD_WRITE],
    },
    children: [
      {
        path: 'milestone/:id?',
        name: types.DRMD_MILESTONE,
        component: () => import('@/modules/drmd_app/views/Milestones'), //DLCM-20450: Change DRMD component routes
        // props: true,
        meta: {
          parent: types.DLCM_RELEASE_MANAGEMENT_DASHBOARD,
          isRedirect: true,
        },
      },
      {
        path: 'documentation/:id?',
        name: types.DRMD_DOCUMENTATION,
        component: () => import('@/modules/drmd_app/views/DocumentsContainer'),
        props: true,
        meta: {
          parent: types.DLCM_RELEASE_MANAGEMENT_DASHBOARD,
          isRedirect: true,
          // isScrollUpDisabled: true,
        },
      },
      {
        path: 'tests',
        name: types.DRMD_TESTS,
        component: () => import('@/modules/demo/components/Sub2'),
        props: true,
        meta: {
          parent: types.DLCM_RELEASE_MANAGEMENT_DASHBOARD,
        },
      },
      {
        path: 'risks',
        name: types.DRMD_RISKS,
        component: () => import('@/modules/drmd_app/views/Risks'),
        props: true,
        meta: {
          parent: types.DLCM_RELEASE_MANAGEMENT_DASHBOARD,
        },
      },
      {
        path: 'risks/create',
        name: types.DRMD_RISKS_CREATE,
        component: () => import('@/modules/drmd_app/components/RiskCreate'),
        props: true,
        meta: {
          parent: types.DLCM_RELEASE_MANAGEMENT_DASHBOARD,
        },
      },
      {
        path: 'risks/edit/:riskId',
        name: types.DRMD_RISKS_EDIT,
        component: () => import('@/modules/drmd_app/components/EditRisk'),
        props: true,
        meta: {
          parent: types.DLCM_RELEASE_MANAGEMENT_DASHBOARD,
        },
      },
      {
        path: 'deployments',
        name: types.DRMD_DEPLOYMENTS,
        component: () => import('@/modules/demo/components/Sub2'),
        props: true,
        meta: {
          parent: types.DLCM_RELEASE_MANAGEMENT_DASHBOARD,
        },
      },
    ],
  },
  {
    path: '/software/deployments',
    name: types.CM_DEVELOPER_MODE_DEPLOYMENTS,
    component: () => import('@/modules/campaign_management/views/DeveloperMode/DeploymentOverview'),
    meta: {
      roles: [hasCommonDmTestDeploymentsRoles],
      parent: types.CAMPAIGN_MANAGEMENT,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faPlug')).faPlug,
      isSubNavigation: true,
      isMainNavigation: true,
    },
  },
  {
    path: '/software/campaigns',
    name: types.CM_CAMPAIGNS,
    component: () => import('@/modules/campaign_management/views/Campaigns/CampaignOverview'),
    meta: {
      roles: [hasCommonCampaignRoles],
      parent: types.CAMPAIGN_MANAGEMENT,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faRocket')).faRocket,
      isSubNavigation: true,
      isMainNavigation: true,
    },
  },
  {
    path: '/deployment-tracking',
    redirect: { name: types.DEPLOYMENT_TRACKING_DASHBOARD },
    name: types.DEPLOYMENT_TRACKING,
    meta: {
      roles: [() => hasRoles([RoleEnum.PERM_DEPLOYMENT_TRACKING_READ, RoleEnum.UT_WEBAPP])],
      parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
      isTopNavigation: true,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faChartSimple')).faChartSimple,
    },
  },
  {
    path: '/deployment-tracking/dashboard',
    name: types.DEPLOYMENT_TRACKING_DASHBOARD,
    component: () => import('@/modules/deployment_tracking/components/dashboard/Dashboard'),
    meta: {
      parent: types.DEPLOYMENT_TRACKING,
      isSubNavigation: true,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faCarSide')).faCarSide,
    },
  },
  {
    path: '/deployment-tracking/data-import',
    redirect: { name: types.DEPLOYMENT_TRACKING_DATAIMPORT_SALES_DATA },
    name: types.DEPLOYMENT_TRACKING_DATAIMPORT,
    meta: {
      parent: types.DEPLOYMENT_TRACKING,
      isSubNavigation: true,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faCloudArrowUp')).faCloudArrowUp,
    },
  },
  {
    path: '/deployment-tracking/data-import/sales-data',
    name: types.DEPLOYMENT_TRACKING_DATAIMPORT_SALES_DATA,
    component: () => import('@/modules/deployment_tracking/views/import/SalesDataImport'),
    meta: {
      parent: types.DEPLOYMENT_TRACKING_DATAIMPORT,
      isSubNavigation: true,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faMoneyBills')).faMoneyBills,
    },
  },
  {
    path: '/deployment-tracking/data-import/fleet-capabilities',
    name: types.DEPLOYMENT_TRACKING_DATAIMPORT_FLEET_CAPABILITY,
    component: () => import('@/modules/deployment_tracking/views/import/FleetCapabilityImport'),
    meta: {
      parent: types.DEPLOYMENT_TRACKING_DATAIMPORT,
      isSubNavigation: true,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faFlag')).faFlag,
    },
  },
  {
    path: '/deployment-tracking/data-import/tracking',
    component: () => import('@/modules/deployment_tracking/views/import/TrackingImport'),
    name: types.DEPLOYMENT_TRACKING_DATAIMPORT_TRACKING_DATA,
    meta: {
      parent: types.DEPLOYMENT_TRACKING_DATAIMPORT,
      isSubNavigation: true,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faDisplay')).faDisplay,
    },
  },
  {
    path: '/software',
    name: types.CAMPAIGN_MANAGEMENT,
    component: () => import('@/components/AutoRedirectToFirstNavigationChild'),
    props: true,
    meta: {
      isRedirect: true,
      isTopNavigation: true,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faBox')).faBox,
    },
  },
  {
    path: '/data-analytics/e311-developer-mode',
    name: types.DATA_E3_1_1_ORU_DEVELOPER_MODE_DASHBOARD,
    component: () => import('@/modules/data_analytics/views/E311DeveloperModeDashboard'),
    props: true,
    meta: {
      roles: [() => hasRoles([RoleEnum.PERM_ERROR_TRACKING_READ, RoleEnum.VP_E311, RoleEnum.UT_ORU_MEB])],
      isTopNavigation: true,
      parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faChartSimple')).faChartSimple,
    },
  },
  {
    path: '/data-analytics/e311-e312-aec-vehicle-inventory',
    name: types.DATA_E3_1_1_E3_1_2_AEC_ERROR_TRACKING_DASHBOARD,
    component: () => import('@/modules/data_analytics/views/E311E312AutoEdgeContainerErrorTracking'),
    props: true,
    meta: {
      roles: [() => hasRoles([RoleEnum.PERM_ERROR_TRACKING_READ, RoleEnum.UT_CU])],
      isTopNavigation: true,
      parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faChartSimple')).faChartSimple,
    },
  },
  {
    path: '/data-analytics/e311-e312-aec-deployment-tracking',
    name: types.DATA_E3_1_1_E3_1_2_AEC_DEPLOYMENT_TRACKING_DASHBOARD,
    component: () => import('@/modules/data_analytics/views/E311E312AutoEdgeContainerDeploymentTracking'),
    props: true,
    meta: {
      roles: [() => hasRoles([RoleEnum.PERM_DEPLOYMENT_TRACKING_READ, RoleEnum.UT_CU])],
      isTopNavigation: true,
      parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faChartSimple')).faChartSimple,
    },
  },
  {
    path: '/software/data-load',
    name: types.DATA_E3_1_1_DATA_LOAD_DASHBOARD,
    component: () => import('@/modules/campaign_management/views/Campaigns/DataLoadOverview'),
    props: false,
    meta: {
      roles: [RoleEnum.PERM_DATALOAD_READ],
      isSubNavigation: true,
      parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faChartSimple')).faChartSimple,
    },
  },
  {
    path: '/software/deployments/create',
    meta: {
      roles: [DeprecatedRightEnum.CM_E3_2_0_ORU_MANAGER],
    },
    children: [
      {
        path: '',
        name: types.CM_DEPLOYMENTS_CREATE_SELECTION,
        component: () => import('@/modules/campaign_management/views/DeveloperMode/Create/DeploymentTypeSelection'),
        meta: {
          parent: types.CM_DEVELOPER_MODE_DEPLOYMENTS,
        },
      },
    ],
  },
  {
    path: '/software/campaigns/create',
    meta: {
      roles: [hasCommonCampaignRoles],
    },
    children: [
      {
        path: '',
        name: types.CM_CAMPAIGN_CREATE_SELECTION,
        component: () => import('@/modules/campaign_management/views/Campaigns/Create/CampaignTypeSelection'),
        meta: {
          parent: types.CM_CAMPAIGNS,
        },
      },
    ],
  },
  {
    path: '/software/campaigns/data-load-management/:campaignId',
    name: types.DATA_LOAD_CAMPAIGN_EDIT,
    component: () => import('@/components/AutoRedirectToFirstNavigationChild'),
    props: (route) => ({
      campaignId: Number(route.params.campaignId),
    }),
    meta: {
      isRedirect: true,
      parent: types.CM_CAMPAIGNS,
      isBreadcrumbDisabled: true,
      childrenTopbarComponent: () =>
        import('@/modules/data_load_management/components/DataLoadManagementCampaignTopbar'),
    },
  },
  {
    path: '/software/campaigns/data-load-management/:campaignId/overview',
    name: types.DATA_LOAD_OVERVIEW,
    component: () => import('@/modules/data_load_management/DataLoadManagementOverview'),
    props: (route) => ({
      campaignId: Number(route.params.campaignId),
    }),
    meta: {
      parent: types.DATA_LOAD_CAMPAIGN_EDIT,
      isTopbarNavigation: true,
    },
  },
  {
    path: '/software/campaigns/data-load-management/:campaignId/properties',
    name: types.DATA_LOAD_PROPERTIES,
    component: () => import('@/modules/data_load_management/DataLoadManagementEdit'),
    props: (route) => ({
      campaignId: Number(route.params.campaignId),
    }),
    meta: {
      parent: types.DATA_LOAD_CAMPAIGN_EDIT,
      isTopbarNavigation: true,
    },
  },
  {
    path: '/software/campaigns/data-load-management/:campaignId/batches',
    name: types.DATA_LOAD_BATCHES,
    component: () => import('@/modules/data_load_management/DataLoadManagementBatches'),
    props: (route) => ({
      campaignId: Number(route.params.campaignId),
    }),
    meta: {
      parent: types.DATA_LOAD_CAMPAIGN_EDIT,
      isTopbarNavigation: true,
    },
  },
  {
    path: '/software/campaigns/oru-smart-campaign/:smartCampaignId',
    name: types.ORU_SMART_CAMPAIGN_EDIT,
    redirect: { name: types.ORU_SMART_CAMPAIGN_EDIT_TARGET_BASELINE },
    component: () => import('@/modules/oru_smart_campaigns/components/OruSmartCampaignTopbar'),
    props: (route) => ({
      smartCampaignId: Number(route.params.smartCampaignId),
    }),
    meta: {
      isRedirect: true,
      layout: DefaultLayout,
      parent: types.CM_CAMPAIGNS,
    },
    children: [
      {
        path: 'software-details',
        name: types.ORU_SMART_CAMPAIGN_EDIT_TARGET_BASELINE,
        component: () => import('@/modules/oru_smart_campaigns/view/SmartCampaignSoftwareDetails'),
        props: (route) => ({
          smartCampaignId: Number(route.params.smartCampaignId),
        }),
        meta: {
          parent: types.ORU_SMART_CAMPAIGN_EDIT,
          isRedirect: true,
        },
      },
      {
        path: 'vehicles',
        name: types.ORU_SMART_CAMPAIGN_EDIT_TARGET_VEHICLES,
        component: () => import('@/modules/oru_smart_campaigns/view/SmartCampaignTargetVehicleDetails'),
        props: (route) => ({
          smartCampaignId: Number(route.params.smartCampaignId),
        }),
        meta: {
          parent: types.ORU_SMART_CAMPAIGN_EDIT,
          isRedirect: true,
        },
      },
      {
        path: 'request-deployment',
        name: types.ORU_SMART_CAMPAIGN_EDIT_REQUEST_DEPLOYMENT,
        component: () => import('@/modules/oru_smart_campaigns/view/SmartCampaignDeploymentRequest'),
        props: (route) => ({
          smartCampaignId: Number(route.params.smartCampaignId),
        }),
        meta: {
          parent: types.ORU_SMART_CAMPAIGN_EDIT,
          isRedirect: true,
        },
      },
      {
        path: 'approve-deployment',
        name: types.ORU_SMART_CAMPAIGN_EDIT_ACCEPT_DEPLOYMENT,
        component: () => import('@/modules/oru_smart_campaigns/view/SmartCampaignDeploymentActions'),
        props: (route) => ({
          smartCampaignId: Number(route.params.smartCampaignId),
        }),
        meta: {
          parent: types.ORU_SMART_CAMPAIGN_EDIT,
          isRedirect: true,
        },
      },
      {
        path: 'plan-deployment',
        name: types.ORU_SMART_CAMPAIGN_EDIT_PLAN_DEPLOYMENT,
        component: () => import('@/modules/oru_smart_campaigns/view/SmartCampaignDeploymentPlan'),
        props: (route) => ({
          smartCampaignId: Number(route.params.smartCampaignId),
        }),
        meta: {
          parent: types.ORU_SMART_CAMPAIGN_EDIT,
          isRedirect: true,
        },
      },
      {
        path: 'rollout',
        name: types.ORU_SMART_CAMPAIGN_EDIT_ROLLOUT,
        component: () => import('@/modules/oru_smart_campaigns/view/SmartCampaignDeploymentRollout'),
        props: (route) => ({
          smartCampaignId: Number(route.params.smartCampaignId),
        }),
        meta: {
          parent: types.ORU_SMART_CAMPAIGN_EDIT,
          isRedirect: true,
        },
      },
    ],
  },
  {
    path: '/software/release/create',
    meta: {
      roles: [hasCommonSwProjectRoles],
    },
    children: [
      {
        path: '',
        name: types.CM_RELEASES_CREATE,
        component: () => import('@/modules/campaign_management/views/Releases/Create/TypeSelection'),
        meta: {
          parent: types.CM_RELEASES,
        },
      },
    ],
  },
  {
    path: '/software/projects/drm/:swProjectId',
    name: types.CM_DRM_SW_PROJECT_EDIT,
    component: () => import('@/modules/campaign_management/views/SoftwareProjects/Drm/SwProjectPage'),
    props: true,
    meta: {
      layout: DefaultLayout,
      roles: [RoleEnum.UT_DRM, RoleEnum.PERM_SW_PROJECT_READ],
      parent: types.CM_RELEASES,
      breadcrumbComponent: () => import('@/modules/campaign_management/components/SoftwareProjects/GenericBreadcrumb'),
    },
  },
  {
    path: '/software/projects/drm/:swProjectId/release-version/:releaseVersionId',
    name: types.CM_DRM_RELEASE_VERSION,
    component: () => import('@/modules/campaign_management/views/Releases/Edit/Drm/DrmReleaseContainer'),
    props: true,
    meta: {
      parent: types.CM_DRM_SW_PROJECT_EDIT,
      isRedirect: true,
      breadcrumbComponent: () => import('@/modules/campaign_management/components/Drm/DrmReleaseVersionBreadcrumb'),
    },
    children: [
      {
        path: 'properties',
        name: types.CM_DRM_RELEASE_VERSION_PROPERTIES_EDIT,
        component: () => import('@/modules/campaign_management/views/Releases/Edit/Drm/ReleaseVersionEdit'),
        props: true,
        meta: {
          parent: types.CM_DRM_RELEASE_VERSION,
          isTopbarNavigation: true,
        },
      },
      {
        path: 'configuration',
        name: types.CM_DRM_RELEASE_VERSION_CONFIGURATION,
        component: () => import('@/modules/campaign_management/views/Releases/Edit/Drm/ReleaseVersionConfiguration'),
        props: true,
        meta: {
          isTopbarNavigation: true,
          parent: types.CM_DRM_RELEASE_VERSION,
        },
      },
      {
        path: 'vehicles',
        name: types.CM_DRM_RELEASE_VERSION_PROPERTIES_EDIT_TARGET_VEHICLES,
        component: () => import('@/modules/campaign_management/components/VinList/EmbeddedTargetVehicles'),
        props: (route) => ({
          entityId: route.params.releaseVersionId,
          entityType: EntityType.DRM_RELEASE_VERSION,
        }),
        meta: {
          isTopbarNavigation: true,
          parent: types.CM_DRM_RELEASE_VERSION,
        },
      },
      {
        path: 'deployment',
        name: types.CM_DRM_RELEASE_DEPLOYMENTS,
        component: () => import('@/modules/campaign_management/views/Releases/Edit/Drm/ReleaseDeployment'),
        props: true,
        meta: {
          roles: [RoleEnum.UT_DRM, RoleEnum.PERM_DEPLOYMENT_READ],
          isTopbarNavigation: true,
          parent: types.CM_DRM_RELEASE_VERSION,
        },
      },
    ],
  },
  {
    path: '/software/projects/drm/:swProjectId/release-version/:releaseVersionId/deployment/:deploymentId',
    name: types.CM_DRM_DEPLOYMENT,
    component: () => import('@/modules/campaign_management/views/Deployments/Drm/DrmDeploymentContainer'),
    props: true,
    meta: {
      parent: types.CM_DRM_RELEASE_DEPLOYMENTS,
      isRedirect: true,
    },
    children: [
      {
        path: 'overview',
        name: types.CM_DRM_DEPLOYMENT_OVERVIEW,
        component: () => import('@/modules/campaign_management/views/Deployments/Drm/Overview'),
        props: true,
        meta: {
          roles: [RoleEnum.UT_DRM, RoleEnum.PERM_DEPLOYMENT_READ],
          isTopbarNavigation: true,
          parent: types.CM_DRM_DEPLOYMENT,
        },
      },
      {
        path: 'parameters',
        name: types.CM_DRM_DEPLOYMENT_PARAMETER,
        component: () => import('@/modules/campaign_management/views/Deployments/Drm/Parameters'),
        props: true,
        meta: {
          roles: [RoleEnum.UT_DRM, RoleEnum.PERM_DEPLOYMENT_READ],
          isTopbarNavigation: true,
          parent: types.CM_DRM_DEPLOYMENT,
        },
      },
      {
        path: 'vehicles',
        name: types.CM_DRM_DEPLOYMENT_TARGET_VEHICLES,
        component: () => import('@/modules/campaign_management/components/VinList/EmbeddedTargetVehicles'),
        props: (route) => ({
          entityId: route.params.deploymentId,
          entityType: EntityType.DRM_DEPLOYMENT,
        }),
        meta: {
          roles: [RoleEnum.UT_DRM, RoleEnum.PERM_DEPLOYMENT_READ],
          isTopbarNavigation: true,
          parent: types.CM_DRM_DEPLOYMENT,
        },
      },
      {
        path: 'deploy',
        name: types.CM_DRM_DEPLOYMENT_DEPLOY,
        component: () => import('@/modules/campaign_management/views/Deployments/Drm/DrmRollout'),
        props: true,
        meta: {
          roles: [RoleEnum.UT_DRM, RoleEnum.PERM_DEPLOYMENT_READ],
          parent: types.CM_DRM_DEPLOYMENT,
          isTopbarNavigation: true,
        },
      },
    ],
  },
  {
    path: '/software/projects/aec/:swProjectId',
    name: types.AEC_SW_PROJECT_EDIT,
    component: () => import('@/modules/campaign_management/views/SoftwareProjects/Aec/SwProjectPage'),
    props: true,
    meta: {
      layout: DefaultLayout,
      roles: [RoleEnum.UT_CU, RoleEnum.PERM_SW_PROJECT_READ],
      parent: types.CM_RELEASES,
      breadcrumbComponent: () => import('@/modules/campaign_management/components/SoftwareProjects/GenericBreadcrumb'),
    },
  },
  {
    path: '/software/projects/aec/:swProjectId',
    name: types.AEC_SW_PROJECT_RELEASE_CONTAINER,
    redirect: { name: types.AEC_SW_PROJECT_RELEASE_OVERVIEW },
    component: () => import('@/modules/campaign_management/views/Releases/Aec/Container'),
    props: true,
    meta: {
      layout: DefaultLayout,
      parent: types.CM_RELEASES,
      isRedirect: true,
      breadcrumbComponent: () => import('@/modules/campaign_management/views/Releases/Aec/ReleaseBreadcrumb'),
    },
    children: [
      {
        path: 'overview',
        name: types.AEC_SW_PROJECT_RELEASE_OVERVIEW,
        component: () => import('@/modules/campaign_management/views/Releases/Aec/ReleaseOverview'),
        props: true,
        meta: {
          parent: types.AEC_SW_PROJECT_RELEASE_CONTAINER,
          isRedirect: true,
        },
      },
      {
        path: 'version-documentation/:documentId?',
        name: types.AEC_SW_PROJECT_RELEASE_VERSION_DOCUMENTATION,
        component: () => import('@/modules/campaign_management/views/Releases/Aec/ReleaseVersionDocumentation'),
        props: true,
        meta: {
          parent: types.AEC_SW_PROJECT_RELEASE_CONTAINER,
          isRedirect: true,
        },
      },
    ],
  },
  {
    path: '/software/projects/aec/:swProjectId/release-candidates/:releaseCandidateId/deployment/:deploymentId/create',
    name: types.AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_CONTAINER_CREATE,
    redirect: { name: types.AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_OVERVIEW },
    component: () =>
      import(
        '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/LiveDeployment/LiveDeploymentContainerCreate'
      ),
    props: true,
    meta: {
      parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_DEPLOYMENTS,
      roles: [RoleEnum.UT_CU, RoleEnum.PERM_DEPLOYMENT_READ],
      isRedirect: true,
      layout: DefaultLayout,
      breadcrumbComponent: () =>
        import(
          '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/LiveDeployment/LiveDeploymentBreadcrumb'
        ),
    },
    children: [
      {
        path: 'overview',
        name: types.AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_OVERVIEW,
        component: () =>
          import(
            '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/LiveDeployment/LiveDeploymentOverview'
          ),
        props: true,
        meta: {
          parent: types.AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_CONTAINER_CREATE,
        },
      },
      {
        path: 'target-vehicles',
        name: types.AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_TARGET_VEHICLES,
        component: () =>
          import(
            '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/LiveDeployment/LiveDeploymentVehicles'
          ),
        props: true,
        meta: {
          parent: types.AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_CONTAINER_CREATE,
        },
      },
      {
        path: 'request',
        name: types.AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_REQUEST,
        component: () =>
          import(
            '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/LiveDeployment/LiveDeploymentRequest'
          ),
        props: true,
        meta: {
          parent: types.AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_CONTAINER_CREATE,
        },
      },
    ],
  },
  {
    path: '/software/projects/aec/:swProjectId/release-candidates/:releaseCandidateId/deployment/:deploymentId/deploy',
    name: types.AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_CONTAINER_DEPLOY,
    redirect: { name: types.AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_ROLLOUT },
    component: () =>
      import(
        '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/LiveDeployment/LiveDeploymentContainerDeploy'
      ),
    props: true,
    meta: {
      parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_DEPLOYMENTS,
      roles: [RoleEnum.UT_CU, RoleEnum.PERM_DEPLOYMENT_READ],
      isRedirect: true,
      layout: DefaultLayout,
      breadcrumbComponent: () =>
        import(
          '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/LiveDeployment/LiveDeploymentBreadcrumb'
        ),
    },
    children: [
      {
        path: 'plan',
        name: types.AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_PLAN,
        component: () =>
          import('@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/LiveDeployment/LiveDeploymentPlan'),
        props: true,
        meta: {
          parent: types.AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_CONTAINER_DEPLOY,
        },
      },
      {
        path: 'rollout',
        name: types.AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_ROLLOUT,
        component: () =>
          import(
            '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/LiveDeployment/LiveDeploymentRollout'
          ),
        props: true,
        meta: {
          parent: types.AEC_SW_PROJECT_RELEASE_LIVE_DEPLOYMENT_CONTAINER_DEPLOY,
        },
      },
    ],
  },
  {
    path: '/software/projects/aec/:swProjectId/release-candidates/:releaseCandidateId',
    name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_CONTAINER,
    redirect: { name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_SOFTWARE },
    component: () => import('@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/Container'),
    props: true,
    meta: {
      layout: DefaultLayout,
      parent: types.AEC_SW_PROJECT_RELEASE_CONTAINER,
      isRedirect: true,
      breadcrumbComponent: () =>
        import('@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/ReleaseCandidateBreadcrumb'),
    },
    children: [
      {
        path: 'software',
        name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_SOFTWARE,
        component: () => import('@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/Software'),
        props: true,
        meta: {
          parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_CONTAINER,
          isRedirect: true,
        },
      },
      {
        path: 'vehicles-scope',
        name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_VEHICLES_SCOPE,
        component: () => import('@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/VehiclesScope'),
        props: true,
        meta: {
          parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_CONTAINER,
          isRedirect: true,
        },
      },
      {
        path: 'checklist',
        name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL,
        redirect: { name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_RELEASE_NOTES },
        component: () => import('@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/Checklist'),
        props: true,
        meta: {
          parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_CONTAINER,
          isTopbarNavigation: true,
        },
        children: [
          {
            path: 'release-notes',
            name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_RELEASE_NOTES,
            component: () =>
              import(
                '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/Checklist/ReleaseNotes/ReleaseNoteProjects'
              ),
            props: true,
            meta: {
              parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL,
              isTopbarNavigation: true,
            },
          },
          {
            path: 'sumo-approval',
            name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_APPROVAL,
            redirect: { name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_APPROVAL_SCM_DOCUMENTATION },
            component: () =>
              import(
                '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/Checklist/SumoApprovalContainer'
              ),
            props: true,
            meta: {
              parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL,
            },
            children: [
              {
                path: 'scm-documentation',
                name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_APPROVAL_SCM_DOCUMENTATION,
                component: () =>
                  import(
                    '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/Checklist/SumoApprovalSteps/ScmDocumentation'
                  ),
                props: true,
                meta: {
                  parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_APPROVAL,
                },
              },
              {
                path: 'functional-validation',
                name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_APPROVAL_FUNCTIONAL_VALIDATION,
                component: () =>
                  import(
                    '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/Checklist/SumoApprovalSteps/FunctionalValidation'
                  ),
                props: true,
                meta: {
                  parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_APPROVAL,
                },
              },
            ],
          },
          {
            path: 'brand-approval',
            name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_BRAND_APPROVAL_CONTAINER,
            redirect: { name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_BRAND_APPROVAL_REVIEW_DOCUMENTATION },
            component: () =>
              import(
                '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/Checklist/BrandApprovalContainer'
              ),
            props: true,
            meta: {
              parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL,
            },
            children: [
              {
                path: 'review-documentation',
                name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_BRAND_APPROVAL_REVIEW_DOCUMENTATION,
                component: () =>
                  import(
                    '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/Checklist/BrandApprovalSteps/ReviewDocuments'
                  ),
                props: true,
                meta: {
                  parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_BRAND_APPROVAL_CONTAINER,
                },
              },
              {
                path: 'submit-approval',
                name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_BRAND_APPROVAL_SUBMIT_APPROVAL,
                component: () =>
                  import(
                    '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/Checklist/SumoApprovalSteps/BrandApproval'
                  ),
                props: true,
                meta: {
                  parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_BRAND_APPROVAL_CONTAINER,
                },
              },
            ],
          },
          {
            path: 'deployment-request',
            name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_DEPLOYMENT_REQUEST,
            redirect: { name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_APPROVE_DEPLOYMENT_REQUEST },
            props: true,
            component: () =>
              import(
                '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/Checklist/DeploymentRequest/DeploymentRequestContainer'
              ),
            meta: {
              parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL,
            },
            children: [
              {
                path: 'approve-deployment-request',
                name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_APPROVE_DEPLOYMENT_REQUEST,
                props: true,
                component: () =>
                  import(
                    '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/Checklist/DeploymentRequest/CreateDeploymentRequest'
                  ),
                meta: {
                  parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_DEPLOYMENT_REQUEST,
                },
              },
              {
                path: 'confirmed-by-sdt',
                name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_CONFIRMED_BY_SDT,
                props: true,
                component: () =>
                  import(
                    '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/Checklist/DeploymentRequest/ConfirmedBySDT'
                  ),
                meta: {
                  parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_DEPLOYMENT_REQUEST,
                },
              },
              {
                path: 'push-to-live',
                name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_PUSH_TOLIVE,
                props: true,
                component: () =>
                  import(
                    '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/Checklist/DeploymentRequest/PushToLive'
                  ),
                meta: {
                  parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_DEPLOYMENT_REQUEST,
                },
              },
              {
                path: 'create-deployment-request',
                name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_SUMO_CREATE_DEPLOYMENT,
                props: true,
                component: () =>
                  import(
                    '@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/Checklist/DeploymentRequest/CreateDeployment'
                  ),
                meta: {
                  parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_APPROVAL_DEPLOYMENT_REQUEST,
                },
              },
            ],
          },
        ],
      },
      {
        path: 'documentation',
        name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_DOCUMENTATION,
        component: () => import('@/modules/campaign_management/views/Releases/Aec/ReleaseCandidate/Documentation'),
        props: true,
        meta: {
          parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_CONTAINER,
          isRedirect: true,
        },
        children: [
          {
            path: 'documents/:documentId',
            name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_DOCUMENTATION_DOCUMENT,
            component: () =>
              import('@/modules/campaign_management/views/Releases/Aec/ReleaseVersionDocumentDetails.vue'),
            props: true,
            meta: {
              parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_DOCUMENTATION,
              isRedirect: true,
            },
          },
        ],
      },
      {
        path: 'test-deployments',
        name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_TESTS,
        component: () => import('@/modules/campaign_management/views/Releases/Aec/ReleaseCandidateTestDeployments'),
        props: true,
        meta: {
          parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_CONTAINER,
          isRedirect: true,
          disabled: featureGate(FF_AEC_TEST_DEPLOYMENT),
        },
      },
      {
        path: 'deployments',
        name: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_DEPLOYMENTS,
        component: () => import('@/modules/campaign_management/views/Releases/Aec/ReleaseCandidateLiveDeployments'),
        props: true,
        meta: {
          parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_CONTAINER,
          roles: [RoleEnum.UT_CU, RoleEnum.PERM_DEPLOYMENT_READ],
          disabled: featureGate(FF_AEC_LIVE_DEPLOYMENT),
        },
      },
    ],
  },
  {
    path: '/software/projects/aec/:swProjectId/release-candidates/:releaseCandidateId/test-deployments/:deploymentId',
    name: types.AEC_TEST_DEPLOYMENTS_CONTAINER,
    component: () => import('@/modules/campaign_management/views/Deployments/Aec/Container.vue'),
    props: true,
    meta: {
      layout: DefaultLayout,
      parent: types.AEC_SW_PROJECT_RELEASE_CANDIDATE_TESTS,
      isRedirect: true,
    },
    children: [
      {
        path: 'info',
        name: types.AEC_TEST_DEPLOYMENTS_INFO,
        component: () => import('@/modules/campaign_management/views/Deployments/Aec/TestDeploymentInfo'),
        props: true,
        meta: {
          parent: types.AEC_TEST_DEPLOYMENTS_CONTAINER,
          isRedirect: true,
        },
      },
      {
        path: 'vehicles',
        name: types.AEC_TEST_DEPLOYMENTS_VEHICLES,
        component: () => import('@/modules/campaign_management/views/Deployments/Aec/TestDeploymentVehicles'),
        props: true,
        meta: {
          parent: types.AEC_TEST_DEPLOYMENTS_CONTAINER,
          isRedirect: true,
        },
      },
      {
        path: 'tracking',
        name: types.AEC_TEST_DEPLOYMENTS_TRACKING,
        component: () => import('@/modules/campaign_management/views/Deployments/Aec/TestDeploymentTracking'),
        props: true,
        meta: {
          parent: types.AEC_TEST_DEPLOYMENTS_CONTAINER,
          isRedirect: true,
        },
      },
      {
        path: 'log',
        name: types.AEC_TEST_DEPLOYMENTS_LOG,
        component: () => import('@/modules/campaign_management/views/Deployments/Aec/TestDeploymentLog'),
        props: true,
        meta: {
          parent: types.AEC_TEST_DEPLOYMENTS_CONTAINER,
          isRedirect: true,
        },
      },
    ],
  },

  {
    path: '/software/campaigns/e3bigloopada/edit/:bigLoopCampaignId',
    name: types.CM_BIGLOOP_CAMPAIGN_EDIT,
    redirect: { name: types.CM_BIGLOOP_CAMPAIGN_EDIT_RELEASE },
    component: () => import('@/modules/campaign_management/components/BigLoop/BigLoopCampaignTopbar'),
    props: (route) => ({
      bigLoopCampaignId: Number(route.params.bigLoopCampaignId),
      releaseId: route.query.releaseId,
    }),
    meta: {
      isRedirect: true,
      layout: DefaultLayout,
      parent: types.CM_DEVELOPER_MODE_DEPLOYMENTS,
    },
    beforeEnter: (to, from, next) => {
      if (!to.query.releaseId && from.query.releaseId) {
        next({
          ...to,
          query: {
            ...to.query,
            releaseId: from.query.releaseId,
          },
        });
      } else {
        next();
      }
    },
    children: [
      {
        path: 'parameters',
        name: types.CM_BIGLOOP_CAMPAIGN_EDIT_PARAMETERS,
        component: () => import('@/modules/campaign_management/views/Campaigns/Edit/BigLoop/BigLoopParameters'),
        props: (route) => ({
          bigLoopCampaignId: Number(route.params.bigLoopCampaignId),
          releaseId: route.query.releaseId,
        }),
        meta: {
          parent: types.CM_BIGLOOP_CAMPAIGN_EDIT,
          isRedirect: true,
        },
        beforeEnter: (to, from, next) => {
          if (!to.query.releaseId && from.query.releaseId) {
            next({
              ...to,
              query: {
                ...to.query,
                releaseId: from.query.releaseId,
              },
            });
          } else {
            next();
          }
        },
      },
      {
        path: 'vehicles',
        name: types.CM_BIGLOOP_CAMPAIGN_EDIT_VEHICLES,
        component: () => import('@/modules/campaign_management/views/Campaigns/Edit/BigLoop/BigLoopVehicleListEditor'),
        props: (route) => ({
          bigLoopCampaignId: Number(route.params.bigLoopCampaignId),
          releaseId: route.query.releaseId,
        }),
        meta: {
          parent: types.CM_BIGLOOP_CAMPAIGN_EDIT,
          isRedirect: true,
        },
        beforeEnter: (to, from, next) => {
          if (!to.query.releaseId && from.query.releaseId) {
            next({
              ...to,
              query: {
                ...to.query,
                releaseId: from.query.releaseId,
              },
            });
          } else {
            next();
          }
        },
      },
      {
        path: 'release',
        name: types.CM_BIGLOOP_CAMPAIGN_EDIT_RELEASE,
        component: () => import('@/modules/campaign_management/views/Campaigns/Edit/BigLoop/BigLoopReleaseSelector'),
        props: (route) => ({
          bigLoopCampaignId: Number(route.params.bigLoopCampaignId),
          releaseId: route.query.releaseId,
        }),
        meta: {
          parent: types.CM_BIGLOOP_CAMPAIGN_EDIT,
          isRedirect: true,
        },
        beforeEnter: (to, from, next) => {
          if (!to.query.releaseId && from.query.releaseId) {
            next({
              ...to,
              query: {
                ...to.query,
                releaseId: from.query.releaseId,
              },
            });
          } else {
            next();
          }
        },
      },
      {
        path: 'rollout',
        name: types.CM_BIGLOOP_CAMPAIGN_EDIT_ROLLOUT,
        component: () => import('@/modules/campaign_management/views/Campaigns/Edit/BigLoop/BigLoopCampaignRollout'),
        props: (route) => ({
          bigLoopCampaignId: Number(route.params.bigLoopCampaignId),
          releaseId: route.query.releaseId,
        }),
        meta: {
          parent: types.CM_BIGLOOP_CAMPAIGN_EDIT,
          isRedirect: true,
        },
        beforeEnter: (to, from, next) => {
          if (!to.query.releaseId && from.query.releaseId) {
            next({
              ...to,
              query: {
                ...to.query,
                releaseId: from.query.releaseId,
              },
            });
          } else {
            next();
          }
        },
      },
      {
        path: 'analytics',
        name: types.CM_BIGLOOP_CAMPAIGN_EDIT_ANALYTICS,
        component: () => import('@/modules/campaign_management/views/Campaigns/Edit/BigLoop/BigLoopCampaignAnalytics'),
        props: (route) => ({
          bigLoopCampaignId: Number(route.params.bigLoopCampaignId),
        }),
        meta: {
          parent: types.CM_BIGLOOP_CAMPAIGN_EDIT,
          isRedirect: true,
        },
        beforeEnter: (to, from, next) => {
          if (!to.query.releaseId && from.query.releaseId) {
            next({
              ...to,
              query: {
                ...to.query,
                releaseId: from.query.releaseId,
              },
            });
          } else {
            next();
          }
        },
      },
    ],
  },
  {
    path: '/software/release/e3bigloopada/:releaseId',
    name: types.CM_BIGLOOP_SW_DELIVERY_RELEASE_EDIT,
    component: () => import('@/components/AutoRedirectToFirstNavigationChild'),
    props: true,
    meta: {
      isRedirect: true,
      roles: [RoleEnum.GLOBAL_CM_E3_2_0_ORU_MANAGER],
      parent: types.CM_RELEASES,
      childrenTopbarComponent: () => import('@/modules/campaign_management/components/BigLoop/BigLoopReleaseTopbar'),
      isBreadcrumbDisabled: true,
    },
  },
  {
    path: '/software/release/e3bigloopada/:releaseId/overview',
    name: types.CM_BIGLOOP_SW_DELIVERY_RELEASE_EDIT_OVERVIEW,
    component: () => import('@/modules/campaign_management/views/Releases/Edit/BigLoop/BigLoopReleaseOverview'),
    props: true,
    meta: {
      roles: [RoleEnum.GLOBAL_CM_E3_2_0_ORU_MANAGER],
      isTopbarNavigation: true,
      parent: types.CM_BIGLOOP_SW_DELIVERY_RELEASE_EDIT,
    },
  },
  {
    path: '/software/release/e3bigloopada/:releaseId/properties',
    name: types.CM_BIGLOOP_SW_DELIVERY_RELEASE_EDIT_PROPERTIES,
    component: () => import('@/modules/campaign_management/views/Releases/BigLoop'),
    props: true,
    meta: {
      roles: [RoleEnum.GLOBAL_CM_E3_2_0_ORU_MANAGER],
      isTopbarNavigation: true,
      parent: types.CM_BIGLOOP_SW_DELIVERY_RELEASE_EDIT,
    },
  },
  // TODO: capybaras/948931 restore R4C logic of release notes
  /*
  {
    path: '/software/release/e3bigloopada/:releaseId/approvals/:id?',
    name: types.CM_BIGLOOP_SW_DELIVERY_RELEASE_EDIT_APPROVALS,
    component: () => import('@/modules/campaign_management/views/Releases/Edit/BigLoop/BigLoopReleaseApprovals'),
    props: true,
    meta: {
      roles: [RoleEnum.GLOBAL_CM_E3_2_0_ORU_MANAGER],
      isTopbarNavigation: true,
      parent: types.CM_BIGLOOP_SW_DELIVERY_RELEASE_EDIT,
    },
  },
 */
  {
    path: '/software/campaigns/e3oru20/edit/:oru20CampaignId',
    name: types.CM_ORU20_CAMPAIGN_EDIT,
    redirect: { name: types.CM_ORU20_CAMPAIGN_EDIT_RELEASE },
    component: () => import('@/modules/campaign_management/components/Oru20/Oru20CampaignTopbar'),
    props: (route) => ({
      oru20CampaignId: Number(route.params.oru20CampaignId),
      releaseId: route.query.releaseId,
    }),
    meta: {
      isRedirect: true,
      layout: DefaultLayout,
      parent: types.CM_DEVELOPER_MODE_DEPLOYMENTS,
    },
    beforeEnter: (to, from, next) => {
      if (!to.query.releaseId && from.query.releaseId) {
        next({
          ...to,
          query: {
            ...to.query,
            releaseId: from.query.releaseId,
          },
        });
      } else {
        next();
      }
    },
    children: [
      {
        path: 'parameters',
        name: types.CM_ORU20_CAMPAIGN_EDIT_PARAMETERS,
        component: () => import('@/modules/campaign_management/views/Campaigns/Edit/Oru20/Oru20Parameters'),
        props: (route) => ({
          oru20CampaignId: Number(route.params.oru20CampaignId),
          releaseId: route.query.releaseId,
        }),
        meta: {
          parent: types.CM_ORU20_CAMPAIGN_EDIT,
          isRedirect: true,
        },
        beforeEnter: (to, from, next) => {
          if (!to.query.releaseId && from.query.releaseId) {
            next({
              ...to,
              query: {
                ...to.query,
                releaseId: from.query.releaseId,
              },
            });
          } else {
            next();
          }
        },
      },
      {
        path: 'vehicles',
        name: types.CM_ORU20_CAMPAIGN_EDIT_VEHICLES,
        component: () => import('@/modules/campaign_management/views/Campaigns/Edit/Oru20/Oru20VehicleListEditor'),
        props: (route) => ({
          oru20CampaignId: Number(route.params.oru20CampaignId),
          releaseId: route.query.releaseId,
        }),
        meta: {
          parent: types.CM_ORU20_CAMPAIGN_EDIT,
          isRedirect: true,
        },
        beforeEnter: (to, from, next) => {
          if (!to.query.releaseId && from.query.releaseId) {
            next({
              ...to,
              query: {
                ...to.query,
                releaseId: from.query.releaseId,
              },
            });
          } else {
            next();
          }
        },
      },
      {
        path: 'release',
        name: types.CM_ORU20_CAMPAIGN_EDIT_RELEASE,
        component: () => import('@/modules/campaign_management/views/Campaigns/Edit/Oru20/Oru20ReleaseSelector'),
        props: (route) => ({
          oru20CampaignId: Number(route.params.oru20CampaignId),
          releaseId: route.query.releaseId,
        }),
        meta: {
          parent: types.CM_ORU20_CAMPAIGN_EDIT,
          isRedirect: true,
        },
        beforeEnter: (to, from, next) => {
          if (!to.query.releaseId && from.query.releaseId) {
            next({
              ...to,
              query: {
                ...to.query,
                releaseId: from.query.releaseId,
              },
            });
          } else {
            next();
          }
        },
      },
      {
        path: 'rollout',
        name: types.CM_ORU20_CAMPAIGN_EDIT_ROLLOUT,
        component: () => import('@/modules/campaign_management/views/Campaigns/Edit/Oru20/Oru20CampaignRollout'),
        props: (route) => ({
          oru20CampaignId: Number(route.params.oru20CampaignId),
          releaseId: route.query.releaseId,
        }),
        meta: {
          parent: types.CM_ORU20_CAMPAIGN_EDIT,
          isRedirect: true,
        },
        beforeEnter: (to, from, next) => {
          if (!to.query.releaseId && from.query.releaseId) {
            next({
              ...to,
              query: {
                ...to.query,
                releaseId: from.query.releaseId,
              },
            });
          } else {
            next();
          }
        },
      },
      {
        path: 'analytics',
        name: types.CM_ORU20_CAMPAIGN_EDIT_ANALYTICS,
        component: () => import('@/modules/campaign_management/views/Campaigns/Edit/Oru20/Oru20CampaignAnalytics'),
        props: (route) => ({
          oru20CampaignId: Number(route.params.oru20CampaignId),
        }),
        meta: {
          parent: types.CM_ORU20_CAMPAIGN_EDIT,
          isRedirect: true,
        },
        beforeEnter: (to, from, next) => {
          if (!to.query.releaseId && from.query.releaseId) {
            next({
              ...to,
              query: {
                ...to.query,
                releaseId: from.query.releaseId,
              },
            });
          } else {
            next();
          }
        },
      },
    ],
  },

  {
    path: '/software/release/e3oru20/:releaseId',
    name: types.CM_ORU20_SW_DELIVERY_RELEASE_EDIT,
    component: () => import('@/components/AutoRedirectToFirstNavigationChild'),
    props: true,
    meta: {
      isRedirect: true,
      roles: [DeprecatedRightEnum.CM_E3_2_0_ORU_MANAGER],
      parent: types.CM_RELEASES,
      childrenTopbarComponent: () => import('@/modules/campaign_management/components/Oru20/Oru20ReleaseTopbar'),
      isBreadcrumbDisabled: true,
    },
  },
  {
    path: '/software/release/e3oru20/:releaseId/overview',
    name: types.CM_ORU20_SW_DELIVERY_RELEASE_EDIT_OVERVIEW,
    component: () => import('@/modules/campaign_management/views/Releases/Edit/Oru20/Oru20ReleaseOverview'),
    props: true,
    meta: {
      roles: [DeprecatedRightEnum.CM_E3_2_0_ORU_MANAGER],
      isTopbarNavigation: true,
      parent: types.CM_ORU20_SW_DELIVERY_RELEASE_EDIT,
    },
  },
  {
    path: '/software/release/e3oru20/:releaseId/properties',
    name: types.CM_ORU20_SW_DELIVERY_RELEASE_EDIT_PROPERTIES,
    component: () => import('@/modules/campaign_management/views/Releases/Oru20'),
    props: true,
    meta: {
      roles: [DeprecatedRightEnum.CM_E3_2_0_ORU_MANAGER],
      isTopbarNavigation: true,
      parent: types.CM_ORU20_SW_DELIVERY_RELEASE_EDIT,
    },
  },
  // TODO: capybaras/948931 restore R4C logic of release notes
  /*
  {
    path: '/software/release/e3oru20/:releaseId/approvals/:id?',
    name: types.CM_ORU20_SW_DELIVERY_RELEASE_EDIT_APPROVALS,
    component: () => import('@/modules/campaign_management/views/Releases/Edit/Oru20/Oru20ReleaseApprovals'),
    props: true,
    meta: {
      roles: [DeprecatedRightEnum.CM_E3_2_0_ORU_MANAGER],
      isTopbarNavigation: true,
      parent: types.CM_ORU20_SW_DELIVERY_RELEASE_EDIT,
    },
  },
  */
  {
    path: '/software/vinlist',
    name: types.CM_VIN_LIST,
    component: () => import('@/modules/campaign_management/views/VinList/VinListOverview'),
    meta: {
      roles: [RoleEnum.PERM_IMPORTED_VEHICLE_LIST_READ, RoleEnum.PERM_STANDALONE_VEHICLE_LIST_READ],
      parent: types.TEMPLATE_DASHBOARD_VEHICLE_MANAGEMENT,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faTicket')).faTicket,
      isSubNavigation: true,
    },
  },
  {
    path: '/software/vinlist/:vinListType/:vinListId',
    name: types.CM_VIN_LIST_EDIT,
    component: () => import('@/modules/campaign_management/views/VinList/VinListContainer'),
    props: true,
    meta: {
      roles: [RoleEnum.PERM_IMPORTED_VEHICLE_LIST_WRITE, RoleEnum.PERM_STANDALONE_VEHICLE_LIST_WRITE],
      parent: types.CM_VIN_LIST,
      isBreadcrumbDisabled: true,
    },
    children: [
      {
        path: 'properties',
        name: types.CM_VIN_LIST_EDIT_PROPERTIES,
        component: () => import('@/modules/campaign_management/views/VinList/VinListEdit'),
        props: true,
        meta: {
          roles: [RoleEnum.PERM_IMPORTED_VEHICLE_LIST_WRITE, RoleEnum.PERM_STANDALONE_VEHICLE_LIST_WRITE],
          parent: types.CM_VIN_LIST_EDIT,
        },
      },
      {
        path: 'target-vehicles',
        name: types.CM_VIN_LIST_EDIT_TARGET_VEHICLES,
        component: () => import('@/modules/campaign_management/views/VinList/VinListTargetVehicles'),
        props: true,
        meta: {
          roles: [RoleEnum.PERM_IMPORTED_VEHICLE_LIST_WRITE, RoleEnum.PERM_STANDALONE_VEHICLE_LIST_WRITE],
          parent: types.CM_VIN_LIST_EDIT,
        },
      },
    ],
  },
  {
    path: '/data-analytics/deployment-tracking',
    name: types.DATA_E3_1_1_DEPLOYMENT_TRACKING_DASHBOARD,
    component: () => import('@/modules/data_analytics/views/DeploymentTrackingDashboard'),
    meta: {
      roles: [() => hasRoles([RoleEnum.PERM_DEPLOYMENT_TRACKING_READ, RoleEnum.VP_E311, RoleEnum.UT_ORU_MEB])],
      isSubNavigation: true,
      parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
    },
  },
  {
    path: '/data-analytics/e3-12-deployment-tracking',
    name: types.DATA_E3_1_2_DEPLOYMENT_TRACKING_DASHBOARD,
    component: () => import('@/modules/data_analytics/views/E312DeploymentTrackingDashboard'),
    meta: {
      roles: [() => hasRoles([RoleEnum.PERM_DEPLOYMENT_TRACKING_READ, RoleEnum.VP_E312, RoleEnum.UT_ORU_NEXT])],
      isSubNavigation: true,
      parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
    },
  },
  {
    path: '/data-analytics/e3-20-developer-dashboard',
    name: types.DATA_E3_2_0_ORU_DEVELOPER_DASHBOARD,
    component: () => import('@/modules/data_analytics/views/E320DeveloperDashboard'),
    meta: {
      roles: [() => hasRoles([RoleEnum.PERM_ERROR_TRACKING_READ, RoleEnum.VP_E320])],
      isSubNavigation: true,
      parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
    },
  },
  {
    path: '/data-analytics/error-tracking/:withSelectedFilters?',
    name: types.DATA_E3_1_1_ERROR_TRACKING_DASHBOARD,
    component: () => import('@/modules/data_analytics/views/ErrorTrackingDashboard'),
    props: (route) => ({
      withSelectedFilters: route?.params?.withSelectedFilters,
    }),
    meta: {
      roles: [() => hasRoles([RoleEnum.PERM_ERROR_TRACKING_READ, RoleEnum.VP_E311, RoleEnum.UT_ORU_MEB])],
      isSubNavigation: true,
      parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
    },
  },
  {
    path: '/data-analytics/unece-error-tracking/:withSelectedFilters?',
    name: types.DATA_E3_1_1_UNECE_ERROR_TRACKING_DASHBOARD,
    component: () => import('@/modules/data_analytics/views/UNECEErrorTrackingDashboard'),
    props: (route) => ({
      withSelectedFilters: route?.params?.withSelectedFilters,
    }),
    meta: {
      roles: [() => hasRoles([RoleEnum.PERM_ERROR_TRACKING_READ, RoleEnum.VP_E311, RoleEnum.UT_ORU_MEB])],
      isSubNavigation: true,
      parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
      disabled: featureGate(FF_DATA_UNECE),
    },
  },
  {
    path: '/data-analytics/e310-oru23',
    name: types.DATA_ANALYTICS_ORU_23,
    component: () => import('@/modules/data_analytics/views/E311Oru23Dashboard'),
    meta: {
      parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
      roles: [
        () =>
          hasRoles([RoleEnum.PERM_DEPLOYMENT_TRACKING_READ, RoleEnum.VP_MQB_MLB, RoleEnum.UT_ORU_2_3]) ||
          hasRoles([DeprecatedRightEnum.VIEW_ORU_23_DATA, RoleEnum.VP_MQB_MLB, RoleEnum.UT_ORU_2_3]),
      ],
      isTopNavigation: true,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faChartLine')).faChartLine,
    },
  },
  {
    path: '/data-analytics/e310-oru23/error-tracking',
    name: types.DATA_E3_1_0_ORU_23_ERROR_TRACKING_DASHBOARD,
    component: () => import('@/modules/data_analytics/views/E311Oru23ErrorTrackingDashboard'),
    meta: {
      parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
      roles: [
        () =>
          hasRoles([RoleEnum.PERM_ERROR_TRACKING_READ, RoleEnum.VP_MQB_MLB, RoleEnum.UT_ORU_2_3]) ||
          hasRoles([DeprecatedRightEnum.VIEW_ORU_23_DATA, RoleEnum.VP_MQB_MLB, RoleEnum.UT_ORU_2_3]),
      ],
      isTopNavigation: true,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faChartLine')).faChartLine,
    },
  },
  {
    path: '/demo',
    name: types.DEMO,
    component: () => import('@/modules/demo/views/Overview'),
    meta: {
      isBreadcrumbDisabled: true,
      isRedirect: true,
    },
    children: [
      {
        path: 'layout',
        name: types.DEMO_LAYOUT,
        component: () => import('@/modules/demo/views/Layout'),
        meta: {
          parent: types.DEMO,
          isTopbarNavigation: true,
        },
      },
      {
        path: 'button',
        name: types.DEMO_BUTTON,
        component: () => import('@/modules/demo/views/Button'),
        meta: {
          parent: types.DEMO,
          isTopbarNavigation: true,
        },
      },
      {
        path: 'tags',
        name: types.DEMO_TAGS,
        component: () => import('@/modules/demo/views/Tags'),
        meta: {
          parent: types.DEMO,
          isTopbarNavigation: true,
        },
      },
      {
        path: 'lazystate',
        name: types.DEMO_LAZYSTATE,
        component: () => import('@/modules/demo/views/LazyState'),
        meta: {
          parent: types.DEMO,
          isTopbarNavigation: true,
        },
      },
      {
        path: 'table',
        name: types.DEMO_TABLE,
        component: () => import('@/modules/demo/views/Table'),
        meta: {
          parent: types.DEMO,
          isTopbarNavigation: true,
        },
      },
      {
        path: 'form',
        name: types.DEMO_FORM,
        component: () => import('@/modules/demo/views/Form'),
        meta: {
          parent: types.DEMO,
          isTopbarNavigation: true,
          isRedirect: true,
        },
        children: [
          {
            path: 'buttonbar',
            name: types.DEMO_FORM_BUTTONBAR,
            component: () => import('@/modules/demo/views/Form/ButtonBar'),
            meta: {
              parent: types.DEMO_FORM,
              isTopbarNavigation: true,
            },
          },
          {
            path: 'use',
            name: types.DEMO_FORM_USEFORM,
            component: () => import('@/modules/demo/views/Form/Use'),
            meta: {
              parent: types.DEMO_FORM,
              isTopbarNavigation: true,
            },
          },
          {
            path: 'helpers',
            name: types.DEMO_FORM_HELPERS,
            component: () => import('@/modules/demo/views/Form/Helpers'),
            meta: {
              parent: types.DEMO_FORM,
              isTopbarNavigation: true,
            },
          },
          {
            path: 'upload',
            name: types.DEMO_FORM_UPLOAD,
            component: () => import('@/modules/demo/views/Form/Upload'),
            meta: {
              parent: types.DEMO_FORM,
              isTopbarNavigation: true,
            },
          },
        ],
      },
      {
        path: 'topbar',
        name: types.DEMO_TOPBAR,
        component: () => import('@/modules/demo/views/Topbar'),
        meta: {
          parent: types.DEMO,
          isTopbarNavigation: true,
        },
      },
      {
        path: 'widget',
        name: types.DEMO_WIDGET,
        component: () => import('@/modules/demo/views/Widget'),
        meta: {
          parent: types.DEMO,
          isTopbarNavigation: true,
        },
      },
      {
        path: 'card',
        name: types.DEMO_CARD,
        component: () => import('@/modules/demo/views/DemoCard'),
        meta: {
          parent: types.DEMO,
          isTopbarNavigation: true,
        },
      },
      {
        path: 'stepper',
        name: types.DEMO_STEPPER,
        component: () => import('@/modules/demo/views/DemoStepper'),
        meta: {
          parent: types.DEMO,
          isTopbarNavigation: true,
        },
      },
      {
        path: 'popup',
        name: types.DEMO_POPUP,
        component: () => import('@/modules/demo/views/Popup'),
        meta: {
          parent: types.DEMO,
          isTopbarNavigation: true,
        },
      },
      {
        path: 'navigation/:id?',
        name: types.DEMO_NAVIGATION,
        redirect: { name: types.DEMO_NAVIGATION_SUB3 },
        component: () => import('@/modules/demo/views/Navigation'),
        meta: {
          parent: types.DEMO,
          isTopbarNavigation: true,
          isRedirect: true,
        },
        children: [
          {
            path: 'first',
            name: types.DEMO_NAVIGATION_SUB1,
            component: () => import('@/modules/demo/components/Sub1'),
            props: true,
            meta: {
              parent: types.DEMO_NAVIGATION,
              isScrollUpDisabled: true,
            },
          },
          {
            path: 'second',
            name: types.DEMO_NAVIGATION_SUB2,
            component: () => import('@/modules/demo/components/Sub2'),
            props: true,
            meta: {
              parent: types.DEMO_NAVIGATION,
              isScrollUpDisabled: true,
            },
          },
          {
            path: 'third',
            name: types.DEMO_NAVIGATION_SUB3,
            component: () => import('@/modules/demo/components/Sub3'),
            props: true,
            meta: {
              parent: types.DEMO_NAVIGATION,
              isScrollUpDisabled: true,
            },
            children: [
              {
                path: 'deeper',
                name: 'DEEPER',
                component: () => import('@/modules/demo/components/Sub1'),
                props: true,
                meta: {
                  parent: types.DEMO_NAVIGATION_SUB3,
                  isScrollUpDisabled: true,
                },
              },
            ],
          },
          {
            path: 'INVALID',
            name: types.DEMO_NAVIGATION_SUB4,
            component: () => import('@/components/Error'),
            props: true,
            meta: {
              parent: types.DEMO_NAVIGATION,
              roles: [() => false],
              isScrollUpDisabled: true,
            },
          },
        ],
      },
      {
        path: 'affix',
        name: types.DEMO_AFFIX,
        component: () => import('@/modules/demo/views/ReleaseVersionAffix'),
        meta: {
          parent: types.DEMO,
          isTopbarNavigation: true,
        },
        children: [
          {
            path: 'general',
            name: types.DEMO_AFFIX_SUB1,
            component: () => import('@/modules/demo/components/Temp1'),
            props: true,
            meta: {
              parent: types.DEMO_AFFIX,
              isScrollUpDisabled: true,
            },
          },
          {
            path: 'additional',
            name: types.DEMO_AFFIX_SUB2,
            component: () => import('@/modules/demo/components/Sub2'),
            props: true,
            meta: {
              parent: types.DEMO_AFFIX,
              isScrollUpDisabled: true,
            },
          },
        ],
      },
      {
        path: 'timeline',
        name: types.DEMO_TIMELINE,
        component: () => import('@/modules/demo/views/Timeline'),
        meta: {
          parent: types.DEMO,
          isTopbarNavigation: true,
        },
      },
      {
        path: 'default-page',
        name: types.DEMO_DEFAULT_PAGE_PARENT,
        redirect: { name: types.DEMO_DEFAULT_PAGE },
        meta: {
          parent: types.DEMO,
          isTopbarNavigation: true,
        },
        children: [
          {
            path: 'default',
            name: types.DEMO_DEFAULT_PAGE,
            component: () => import('@/modules/demo/views/DemoDefaultPage'),
            meta: {
              isTopbarNavigation: true,
              layout: DefaultLayout,
              parent: types.DEMO_DEFAULT_PAGE_PARENT,
            },
          },
          {
            path: 'second',
            name: types.DEMO_DEFAULT_SUB_SECOND,
            component: () => import('@/modules/demo/views/DemoDefaultPage'),
            meta: {
              isTopbarNavigation: true,
              layout: DefaultLayout,
              parent: types.DEMO_DEFAULT_PAGE_PARENT,
            },
          },

          {
            path: 'third',
            name: types.DEMO_DEFAULT_SUB_THIRD,
            component: () => import('@/modules/demo/views/DemoDefaultPage'),
            meta: {
              isTopbarNavigation: true,
              layout: DefaultLayout,
              parent: types.DEMO_DEFAULT_PAGE_PARENT,
            },
          },
        ],
      },
      {
        path: 'entity-page',
        name: types.DEMO_ENTITY_PAGE,
        component: () => import('@/modules/demo/views/DemoEntityPage'),
        meta: {
          layout: DefaultLayout,
          parent: types.DEMO,
          isTopbarNavigation: true,
        },
      },
      {
        path: 'comments',
        name: types.DEMO_COMMENTS,
        component: () => import('@/modules/demo/views/Comments'),
        meta: {
          parent: types.DEMO,
          isTopbarNavigation: true,
        },
      },
    ],
  },

  //temporarly disabled
  /*{
        path: '/e3-dlcm-gate/dashboard',
        name: types.E3_DLCM_GATE,
        component: () => import('@/modules/e3_dlcm_gate/views/VerbundRelease/Dashboard'),
        meta: {
          roles: [DeprecatedRightEnum.DLCM_GATE_ACCESS],
          isTopNavigation: true,
          icon: async () => (await import('@fortawesome/free-solid-svg-icons/faLayerGroup')).faLayerGroup,
        },
      },*/

  {
    path: '/software/dlcm-gate/baseline-update/:bluId',
    name: types.DLCM_GATE_BLU,
    component: () => import('@/components/AutoRedirectToFirstNavigationChild'),
    props: true,
    meta: {
      isRedirect: true,
      parent: types.DLCM_GATE_BLU_LIST,
      isBreadcrumbDisabled: true,
      isBreadcrumbHidden: true,
      childrenTopbarComponent: () => import('@/modules/dlcm_gate/views/BluTopbar'),
    },
  },
  {
    path: '/software/dlcm-gate/baseline-update/:bluId/overview',
    name: types.DLCM_GATE_BLU_OVERVIEW,
    component: () => import('@/modules/dlcm_gate/views/BluOverview'),
    props: true,
    meta: {
      parent: types.DLCM_GATE_BLU,
      isTopbarNavigation: true,
    },
  },
  {
    path: '/software/dlcm-gate/baseline-update/:bluId/version',
    name: types.DLCM_GATE_BLU_VERSION,
    component: () => import('@/modules/dlcm_gate/views/BluVersion/Container'),
    props: true,
    meta: {
      parent: types.DLCM_GATE_BLU,
      isTopbarNavigation: true,
      isBreadcrumbDisabled: true,
    },
    children: [
      {
        path: 'general',
        name: types.DLCM_GATE_BLU_VERSION_GENERAL,
        component: () => import('@/modules/dlcm_gate/views/BluVersion/General'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_VERSION,
        },
      },
      {
        path: 'current',
        name: types.DLCM_GATE_BLU_VERSION_CURRENT,
        component: () => import('@/modules/dlcm_gate/views/BluVersion/Current'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_VERSION,
        },
      },
      {
        path: 'target',
        name: types.DLCM_GATE_BLU_VERSION_TARGET,
        component: () => import('@/modules/dlcm_gate/views/BluVersion/Target'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_VERSION,
        },
      },
    ],
  },
  {
    path: '/software/dlcm-gate/baseline-update/:bluId/ecu-release',
    name: types.DLCM_GATE_BLU_ECU_RELEASE_LIST,
    component: () => import('@/modules/dlcm_gate/views/EcuRelease/List'),
    props: true,
    meta: {
      parent: types.DLCM_GATE_BLU,
      isTopbarNavigation: true,
    },
  },
  {
    path: '/software/dlcm-gate/baseline-update/:bluId/ecu-release/:ecuReleaseId',
    name: types.DLCM_GATE_BLU_ECU_RELEASE,
    component: () => import('@/modules/dlcm_gate/views/EcuRelease/List'),
    props: true,
    meta: {
      parent: types.DLCM_GATE_BLU_ECU_RELEASE_LIST,
      isBreadcrumbDisabled: true,
      isBreadcrumbHidden: true,
      childrenTopbarComponent: () => import('@/modules/dlcm_gate/views/EcuReleaseTopbar'),
    },
  },
  {
    path: '/software/dlcm-gate/baseline-update/:bluId/ecu-release/:ecuReleaseId/overview',
    name: types.DLCM_GATE_BLU_ECU_RELEASE_OVERVIEW,
    component: () => import('@/modules/dlcm_gate/views/BluOverview'),
    props: true,
    meta: {
      parent: types.DLCM_GATE_BLU_ECU_RELEASE,
      isTopbarNavigation: true,
    },
  },
  {
    path: '/software/dlcm-gate/baseline-update/:bluId/ecu-release/:ecuReleaseId/version',
    name: types.DLCM_GATE_BLU_ECU_RELEASE_VERSION,
    component: () => import('@/modules/dlcm_gate/views/EcuRelease/Version/Container'),
    props: true,
    meta: {
      parent: types.DLCM_GATE_BLU_ECU_RELEASE,
      isTopbarNavigation: true,
      isBreadcrumbDisabled: true,
    },
    children: [
      {
        path: 'general',
        name: types.DLCM_GATE_BLU_ECU_RELEASE_VERSION_GENERAL,
        component: () => import('@/modules/dlcm_gate/views/EcuRelease/Version/General'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_ECU_RELEASE_VERSION,
        },
      },
      {
        path: 'current',
        name: types.DLCM_GATE_BLU_ECU_RELEASE_VERSION_CURRENT,
        component: () => import('@/modules/dlcm_gate/views/EcuRelease/Version/Current'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_ECU_RELEASE_VERSION,
        },
      },
      {
        path: 'target',
        name: types.DLCM_GATE_BLU_ECU_RELEASE_VERSION_TARGET,
        component: () => import('@/modules/dlcm_gate/views/EcuRelease/Version/Target'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_ECU_RELEASE_VERSION,
        },
      },
      {
        path: 'approval',
        name: types.DLCM_GATE_BLU_ECU_RELEASE_VERSION_APPROVAL,
        component: () => import('@/modules/dlcm_gate/views/EcuRelease/Version/Approval'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_ECU_RELEASE_VERSION,
        },
      },
    ],
  },
  {
    path: '/software/dlcm-gate/baseline-update/:bluId/ecu-release/:ecuReleaseId/gate2',
    name: types.DLCM_GATE_BLU_ECU_RELEASE_GATE2,
    component: () => import('@/modules/dlcm_gate/views/EcuRelease/Gate2/Container'),
    props: true,
    meta: {
      parent: types.DLCM_GATE_BLU_ECU_RELEASE,
      isTopbarNavigation: true,
      isBreadcrumbDisabled: true,
    },
    children: [
      {
        path: 'technical',
        name: types.DLCM_GATE_BLU_ECU_RELEASE_GATE2_TECHNICAL,
        component: () => import('@/modules/dlcm_gate/views/EcuRelease/Gate2/Technical'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_ECU_RELEASE_GATE2,
        },
      },
      {
        path: 'compatibility',
        name: types.DLCM_GATE_BLU_ECU_RELEASE_GATE2_COMPATIBILITY,
        component: () => import('@/modules/dlcm_gate/views/EcuRelease/Gate2/Compatibility'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_ECU_RELEASE_GATE2,
        },
      },
      {
        path: 'functional',
        name: types.DLCM_GATE_BLU_ECU_RELEASE_GATE2_FUNCTIONAL,
        component: () => import('@/modules/dlcm_gate/views/EcuRelease/Gate2/Functional'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_ECU_RELEASE_GATE2,
        },
      },
      {
        path: 'testing',
        name: types.DLCM_GATE_BLU_ECU_RELEASE_GATE2_TESTING,
        component: () => import('@/modules/dlcm_gate/views/EcuRelease/Gate2/Testing'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_ECU_RELEASE_GATE2,
        },
      },
      {
        path: 'organizational',
        name: types.DLCM_GATE_BLU_ECU_RELEASE_GATE2_ORGANIZATIONAL,
        component: () => import('@/modules/dlcm_gate/views/EcuRelease/Gate2/Organizational'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_ECU_RELEASE_GATE2,
        },
      },
      {
        path: 'approval',
        name: types.DLCM_GATE_BLU_ECU_RELEASE_GATE2_APPROVAL,
        component: () => import('@/modules/dlcm_gate/views/EcuRelease/Gate2/Approval'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_ECU_RELEASE_GATE2,
        },
      },
    ],
  },
  {
    path: '/software/dlcm-gate/baseline-update/:bluId/ecu-release/:ecuReleaseId/baselines',
    name: types.DLCM_GATE_BLU_ECU_RELEASE_BASELINES,
    component: () => import('@/modules/dlcm_gate/views/BluOverview'),
    props: true,
    meta: {
      parent: types.DLCM_GATE_BLU_ECU_RELEASE,
      isTopbarNavigation: true,
      isBreadcrumbDisabled: true,
    },
  },
  {
    path: '/software/dlcm-gate/baseline-update/:bluId/customer-vehicle-function',
    name: types.DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_LIST,
    component: () => import('@/modules/dlcm_gate/views/Cvf/List'),
    props: true,
    meta: {
      parent: types.DLCM_GATE_BLU,
      isTopbarNavigation: true,
    },
  },
  {
    path: '/software/dlcm-gate/baseline-update/:bluId/customer-vehicle-function/:cvfId',
    name: types.DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION,
    component: () => import('@/modules/dlcm_gate/views/Cvf/Container'),
    props: true,
    meta: {
      parent: types.DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_LIST,
      isBreadcrumbHidden: true,
    },
    children: [
      {
        path: 'general',
        name: types.DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION_GENERAL,
        component: () => import('@/modules/dlcm_gate/views/Cvf/General'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION,
        },
      },
      {
        path: 'dms-reference',
        name: types.DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION_DMS_REFERENCE,
        component: () => import('@/modules/dlcm_gate/views/Cvf/DmsReference'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION,
        },
      },
      {
        path: 'safety-requirements',
        name: types.DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION_SAFETY_REQUIREMENTS,
        component: () => import('@/modules/dlcm_gate/views/Cvf/SafetyRequirements'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION,
        },
      },
      {
        path: 'approval',
        name: types.DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION_APPROVAL,
        component: () => import('@/modules/dlcm_gate/views/Cvf/Approval'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_CUSTOMER_VEHICLE_FUNCTION_VERSION,
        },
      },
    ],
  },
  {
    path: '/software/dlcm-gate/baseline-update/:bluId/preconditions',
    name: types.DLCM_GATE_BLU_PRECONDITIONS,
    component: () => import('@/modules/dlcm_gate/views/Preconditions/Container'),
    props: true,
    meta: {
      parent: types.DLCM_GATE_BLU,
      isTopbarNavigation: true,
    },
    children: [
      {
        path: 'technical/:name?',
        name: types.DLCM_GATE_BLU_PRECONDITIONS_TECHNICAL,
        component: () => import('@/modules/dlcm_gate/views/Preconditions/Generic'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_PRECONDITIONS,
        },
      },
      {
        path: 'compatibility/:name?',
        name: types.DLCM_GATE_BLU_PRECONDITIONS_COMPATIBILITY,
        component: () => import('@/modules/dlcm_gate/views/Preconditions/Generic'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_PRECONDITIONS,
        },
      },
      {
        path: 'functional/:name?',
        name: types.DLCM_GATE_BLU_PRECONDITIONS_FUNCTIONAL,
        component: () => import('@/modules/dlcm_gate/views/Preconditions/Generic'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_PRECONDITIONS,
        },
      },
      {
        path: 'testing/:name?',
        name: types.DLCM_GATE_BLU_PRECONDITIONS_TESTING,
        component: () => import('@/modules/dlcm_gate/views/Preconditions/Generic'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_PRECONDITIONS,
        },
      },
      {
        path: 'organizational/:name?',
        name: types.DLCM_GATE_BLU_PRECONDITIONS_ORGANIZATIONAL,
        component: () => import('@/modules/dlcm_gate/views/Preconditions/Generic'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_PRECONDITIONS,
        },
      },
    ],
  },
  {
    path: '/software/dlcm-gate/baseline-update/:bluId/risk',
    name: types.DLCM_GATE_BLU_RISK_LIST,
    component: () => import('@/modules/dlcm_gate/views/Risk/List'),
    props: true,
    meta: {
      parent: types.DLCM_GATE_BLU,
      isTopbarNavigation: true,
    },
  },
  {
    path: '/software/dlcm-gate/baseline-update/:bluId/risk/:riskId',
    name: types.DLCM_GATE_BLU_RISK_VERSION,
    component: () => import('@/modules/dlcm_gate/views/Risk/Container'),
    props: true,
    meta: {
      parent: types.DLCM_GATE_BLU_RISK_LIST,
      isBreadcrumbHidden: true,
    },
    children: [
      {
        path: 'general',
        name: types.DLCM_GATE_BLU_RISK_VERSION_GENERAL,
        component: () => import('@/modules/dlcm_gate/views/Risk/General'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_RISK_VERSION,
        },
      },
      {
        path: 'approval',
        name: types.DLCM_GATE_BLU_RISK_VERSION_APPROVAL,
        component: () => import('@/modules/dlcm_gate/views/Risk/Approval'),
        props: true,
        meta: {
          parent: types.DLCM_GATE_BLU_RISK_VERSION,
        },
      },
    ],
  },
  {
    path: '/vehicle-management',
    name: types.VEHICLE_MANAGEMENT,
    redirect: { name: types.VEHICLE_MANAGEMENT_TEST_VEHICLE_DATABASE },
    meta: {
      isTopNavigation: true,
      isRedirect: true,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faVanShuttle')).faVanShuttle,
      roles: [RoleEnum.PERM_TEST_VEHICLE_READ],
    },
    children: [
      {
        path: 'test-vehicle-database',
        name: types.VEHICLE_MANAGEMENT_TEST_VEHICLE_DATABASE,
        component: () => import('@/modules/vehicle-management/views/TestVehicleDatabase'),
        meta: {
          parent: types.TEMPLATE_DASHBOARD_VEHICLE_MANAGEMENT,
        },
      },
      {
        path: 'edit-test-vehicle/:brand/:id',
        name: types.VEHICLE_MANAGEMENT_EDIT_TEST_VEHICLE,
        component: () => import('@/modules/vehicle-management/views/CreateTestVehicleOverview'),
        props: true,
        meta: {
          parent: types.VEHICLE_MANAGEMENT_TEST_VEHICLE_DATABASE,
          isBreadcrumbDisabled: true,
        },
        children: [
          {
            path: 'general-information',
            name: types.VEHICLE_MANAGEMENT_EDIT_TEST_VEHICLE_GENERAL_INFORMATION,
            component: () => import('@/modules/vehicle-management/views/TestVehicleInformation'),
            props: true,
            meta: {
              parent: types.VEHICLE_MANAGEMENT_EDIT_TEST_VEHICLE,
            },
          },
          {
            path: 'location-department',
            name: types.VEHICLE_MANAGEMENT_EDIT_TEST_VEHICLE_LOCATION_DEPARTMENT,
            component: () => import('@/modules/vehicle-management/views/TestVehicleInformation'),
            props: true,
            meta: {
              parent: types.VEHICLE_MANAGEMENT_EDIT_TEST_VEHICLE,
              isScrollUpDisabled: true,
            },
          },
          {
            path: 'construction-state',
            name: types.VEHICLE_MANAGEMENT_EDIT_TEST_VEHICLE_CONSTRUCTION_STATE,
            component: () => import('@/modules/vehicle-management/views/TestVehicleInformation'),
            props: true,
            meta: {
              parent: types.VEHICLE_MANAGEMENT_EDIT_TEST_VEHICLE,
              isScrollUpDisabled: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/software/e311-oru/deployment-request/:drId',
    name: types.E3_1_1_ORU_DEPLOYMENT_REQUEST,
    component: () => import('@/modules/campaign_management/views/Deployments/E311Oru/Requests/DrContainer'),
    props: true,
    meta: {
      roles: [
        () =>
          hasRole(RoleEnum.PERM_TEST_DEPLOYMENT_REQUEST_READ_OWN) ||
          hasRole(RoleEnum.PERM_TEST_DEPLOYMENT_REQUEST_READ_ACTIVE),
      ],
      parent: types.CM_DEVELOPER_MODE_DEPLOYMENTS,
      isRedirect: true,
    },
    children: [
      {
        path: 'general',
        name: types.E3_1_1_ORU_DEPLOYMENT_REQUEST_GENERAL,
        component: () => import('@/modules/campaign_management/views/Deployments/E311Oru/Requests/DrGeneral'),
        props: true,
        meta: {
          parent: types.E3_1_1_ORU_DEPLOYMENT_REQUEST,
        },
      },
      {
        path: 'vehicles',
        name: types.E3_1_1_ORU_DEPLOYMENT_REQUEST_VEHICLES,
        component: () => import('@/modules/campaign_management/views/Deployments/E311Oru/Requests/DrVehicles'),
        props: true,
        meta: {
          parent: types.E3_1_1_ORU_DEPLOYMENT_REQUEST,
        },
      },
      {
        path: 'ecu-updates',
        name: types.E3_1_1_ORU_DEPLOYMENT_REQUEST_ECU_UPDATES,
        component: () => import('@/modules/campaign_management/views/Deployments/E311Oru/Requests/DrEcuUpdates'),
        props: true,
        meta: {
          parent: types.E3_1_1_ORU_DEPLOYMENT_REQUEST,
        },
      },
      {
        path: 'ecu-updates-and-vehicles',
        name: types.E3_1_1_ORU_DEPLOYMENT_REQUEST_ECU_UPDATES_AND_VEHICLES,
        component: () =>
          import('@/modules/campaign_management/views/Deployments/E311Oru/Requests/DrVehiclesAndEcuUpdates'),
        props: true,
        meta: {
          parent: types.E3_1_1_ORU_DEPLOYMENT_REQUEST,
        },
      },
    ],
  },
  {
    path: '/software/e311-oru/deployment-request/:drId/deployment/:id',
    name: types.E3_1_1_ORU_DEPLOYMENT,
    component: () => import('@/modules/campaign_management/views/Deployments/E311Oru/E311OruContainer'),
    props: true,
    meta: {
      parent: types.E3_1_1_ORU_DEPLOYMENT_REQUEST,
      roles: [() => hasRole(RoleEnum.PERM_TEST_DEPLOYMENT_READ)],
      isRedirect: true,
    },
    children: [
      {
        path: 'overview',
        name: types.E3_1_1_ORU_DEPLOYMENT_OVERVIEW,
        component: () => import('@/modules/campaign_management/views/Deployments/E311Oru/E311OruOverview'),
        props: true,
        meta: {
          isTopbarNavigation: true,
          parent: types.E3_1_1_ORU_DEPLOYMENT,
        },
      },
      {
        path: 'information',
        name: types.E3_1_1_ORU_DEPLOYMENT_INFORMATION,
        component: () => import('@/modules/campaign_management/views/Deployments/E311Oru/E311OruInformationContainer'),
        props: true,
        meta: {
          isTopbarNavigation: true,
          parent: types.E3_1_1_ORU_DEPLOYMENT,
          isRedirect: true,
        },
        children: [
          {
            path: 'general',
            name: types.E3_1_1_ORU_DEPLOYMENT_INFORMATION_GENERAL,
            component: () =>
              import('@/modules/campaign_management/views/Deployments/E311Oru/E311OruInformationGeneral'),
            props: true,
            meta: {
              parent: types.E3_1_1_ORU_DEPLOYMENT_INFORMATION,
            },
          },
          {
            path: 'ecu-updates',
            name: types.E3_1_1_ORU_DEPLOYMENT_INFORMATION_ECU_UPDATES,
            component: () =>
              import('@/modules/campaign_management/views/Deployments/E311Oru/E311OruInformationEcuUpdates'),
            props: true,
            meta: {
              parent: types.E3_1_1_ORU_DEPLOYMENT_INFORMATION,
            },
          },
        ],
      },
      {
        path: 'vehicles',
        name: types.E3_1_1_ORU_DEPLOYMENT_VEHICLES,
        component: () => import('@/modules/campaign_management/views/Deployments/E311Oru/E311OruVehicles'),
        props: true,
        meta: {
          isTopbarNavigation: true,
          parent: types.E3_1_1_ORU_DEPLOYMENT,
        },
      },
      {
        path: 'cpl',
        name: types.E3_1_1_ORU_DEPLOYMENT_CPL,
        component: () => import('@/modules/campaign_management/views/Deployments/E311Oru/E311OruCpl'),
        props: true,
        meta: {
          isTopbarNavigation: true,
          parent: types.E3_1_1_ORU_DEPLOYMENT,
        },
      },
      {
        path: 'parameters',
        name: types.E3_1_1_ORU_DEPLOYMENT_PARAMETERS,
        component: () => import('@/modules/campaign_management/views/Deployments/E311Oru/E311OruParameters'),
        props: true,
        meta: {
          isTopbarNavigation: true,
          parent: types.E3_1_1_ORU_DEPLOYMENT,
          isRedirect: true,
        },
        children: [
          {
            path: 'energy',
            name: types.E3_1_1_ORU_DEPLOYMENT_PARAMETERS_ENERGY,
            component: () => import('@/modules/campaign_management/views/Deployments/E311Oru/E311OruParametersEnergy'),
            props: true,
            meta: {
              parent: types.E3_1_1_ORU_DEPLOYMENT_PARAMETERS,
            },
          },
          {
            path: 'overruling',
            name: types.E3_1_1_ORU_DEPLOYMENT_PARAMETERS_OVERRULING,
            component: () =>
              import('@/modules/campaign_management/views/Deployments/E311Oru/E311OruParametersOverruling'),
            props: true,
            meta: {
              parent: types.E3_1_1_ORU_DEPLOYMENT_PARAMETERS,
            },
          },
          {
            path: 'specific',
            name: types.E3_1_1_ORU_DEPLOYMENT_PARAMETERS_SPECIFIC,
            component: () =>
              import('@/modules/campaign_management/views/Deployments/E311Oru/E311OruParametersSpecific'),
            props: true,
            meta: {
              parent: types.E3_1_1_ORU_DEPLOYMENT_PARAMETERS,
            },
          },
        ],
      },
      {
        path: 'tracking',
        name: types.E3_1_1_ORU_DEPLOYMENT_TRACKING,
        component: () => import('@/modules/campaign_management/views/Deployments/E311Oru/E311OruDeploymentTracking'),
        props: true,
        meta: {
          isTopbarNavigation: true,
          parent: types.E3_1_1_ORU_DEPLOYMENT,
        },
      },
    ],
  },
  /********************************************************************************************************
   * DASHBOARD TEMPLATE PAGES
   ********************************************************************************************************/
  {
    path: '/vehicle-management-dashboard',
    name: types.TEMPLATE_DASHBOARD_VEHICLE_MANAGEMENT,
    component: () => import('@/views/DashboardTemplate'),
    meta: {
      isMainNavigation: true,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faCar')).faCar,
    },
    props: () => ({
      placeholderImageName: 'dashboard_template_02.svg',
    }),
    children: [
      {
        path: 'test-vehicle-database',
        name: types.TEMPLATE_DASHBOARD_VEHICLE_MANAGEMENT_TEST_VEHICLE_DATABASE,
        redirect: { name: types.VEHICLE_MANAGEMENT_TEST_VEHICLE_DATABASE },
        meta: {
          isRedirect: true,
          roles: [RoleEnum.PERM_TEST_VEHICLE_READ],
          parent: types.TEMPLATE_DASHBOARD_VEHICLE_MANAGEMENT,
        },
      },
      {
        path: 'vin-list-management',
        name: types.TEMPLATE_DASHBOARD_VEHICLE_MANAGEMENT_VIN_LIST_MANAGEMENT,
        redirect: { name: types.CM_VIN_LIST },
        meta: {
          isRedirect: true,
          roles: [RoleEnum.PERM_IMPORTED_VEHICLE_LIST_READ, RoleEnum.PERM_STANDALONE_VEHICLE_LIST_READ],
          parent: types.TEMPLATE_DASHBOARD_VEHICLE_MANAGEMENT,
        },
      },
    ],
  },
  {
    path: '/data-center',
    name: types.TEMPLATE_DASHBOARD_DATA_CENTER,
    component: () => import('@/views/DashboardTemplate'),
    props: () => ({
      removeSubNavText: true,
      removeUnderConstruction: true,
    }),
    meta: {
      isMainNavigation: true,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faChartLine')).faChartLine,
    },
    children: [
      {
        path: 'data-analytics/e310-oru23',
        name: types.TEMPLATE_DASHBOARD_DATA_CENTER_MLB_MQB_ORU_DEPLOYMENT_TRACKING_ORU_1_0_23,
        redirect: { name: types.DATA_ANALYTICS_ORU_23 },
        meta: {
          roles: [
            () =>
              hasRoles([RoleEnum.PERM_DEPLOYMENT_TRACKING_READ, RoleEnum.VP_MQB_MLB, RoleEnum.UT_ORU_2_3]) ||
              hasRoles([DeprecatedRightEnum.VIEW_ORU_23_DATA, RoleEnum.VP_MQB_MLB, RoleEnum.UT_ORU_2_3]),
          ],
          parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
          dashboardType: 'deploymentTracking',
        },
      },
      {
        path: 'deployment-tracking',
        name: types.TEMPLATE_DASHBOARD_DATA_CENTER_MOD34_DASHBOARD,
        redirect: { name: types.DEPLOYMENT_TRACKING_DASHBOARD },
        meta: {
          isRedirect: true,
          roles: [() => hasRoles([RoleEnum.PERM_DEPLOYMENT_TRACKING_READ, RoleEnum.UT_WEBAPP])],
          parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
          dashboardType: 'deploymentTracking',
        },
      },
      {
        path: 'data-analytics/deployment-tracking',
        name: types.TEMPLATE_DASHBOARD_DATA_CENTER_ORU_1_1_DEPLOYMENT_TRACKING,
        redirect: { name: types.DATA_E3_1_1_DEPLOYMENT_TRACKING_DASHBOARD },
        meta: {
          isRedirect: true,
          roles: [() => hasRoles([RoleEnum.PERM_DEPLOYMENT_TRACKING_READ, RoleEnum.VP_E311, RoleEnum.UT_ORU_MEB])],
          parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
          dashboardType: 'deploymentTracking',
        },
      },
      {
        path: 'data-analytics/e310-oru23/error-tracking',
        name: types.TEMPLATE_DASHBOARD_DATA_CENTER_MLB_MQB_ORU_ERROR_TRACKING_ORU_1_0_23,
        redirect: { name: types.DATA_E3_1_0_ORU_23_ERROR_TRACKING_DASHBOARD },
        meta: {
          roles: [
            () =>
              hasRoles([RoleEnum.PERM_ERROR_TRACKING_READ, RoleEnum.VP_MQB_MLB, RoleEnum.UT_ORU_2_3]) ||
              hasRoles([DeprecatedRightEnum.VIEW_ORU_23_DATA, RoleEnum.VP_MQB_MLB, RoleEnum.UT_ORU_2_3]),
          ],
          parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
          dashboardType: 'errorTracking',
        },
      },
      {
        path: 'data-analytics/error-tracking',
        name: types.TEMPLATE_DASHBOARD_DATA_CENTER_ORU_1_1_ERROR_TRACKING,
        redirect: { name: types.DATA_E3_1_1_ERROR_TRACKING_DASHBOARD },
        meta: {
          isRedirect: true,
          roles: [() => hasRoles([RoleEnum.PERM_ERROR_TRACKING_READ, RoleEnum.VP_E311, RoleEnum.UT_ORU_MEB])],
          parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
          dashboardType: 'errorTracking',
        },
      },
      {
        path: 'data-analytics/unece-error-tracking',
        name: types.TEMPLATE_DASHBOARD_DATA_CENTER_ORU_1_1_UNECE_ERROR_TRACKING,
        redirect: { name: types.DATA_E3_1_1_UNECE_ERROR_TRACKING_DASHBOARD },
        meta: {
          isRedirect: true,
          roles: [() => hasRoles([RoleEnum.PERM_ERROR_TRACKING_READ, RoleEnum.VP_E311, RoleEnum.UT_ORU_MEB])],
          parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
          disabled: featureGate(FF_DATA_UNECE),
          dashboardType: 'errorTracking',
        },
      },
      {
        path: 'data-analytics/e311-developer-mode',
        name: types.TEMPLATE_DASHBOARD_DATA_CENTER_E_3_1_1_ORU_DEVELOPER_MODE,
        redirect: { name: types.DATA_E3_1_1_ORU_DEVELOPER_MODE_DASHBOARD },
        meta: {
          isRedirect: true,
          roles: [() => hasRoles([RoleEnum.PERM_ERROR_TRACKING_READ, RoleEnum.VP_E311, RoleEnum.UT_ORU_MEB])],
          parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
          dashboardType: 'others',
        },
      },
      {
        path: 'data-analytics/e311-e312-aec-deployment-tracking',
        name: types.TEMPLATE_DASHBOARD_DATA_CENTER_E_3_1_1_E_3_1_2_AEC_DEPLOYMENT_TRACKING,
        redirect: { name: types.DATA_E3_1_1_E3_1_2_AEC_DEPLOYMENT_TRACKING_DASHBOARD },
        meta: {
          isRedirect: true,
          roles: [() => hasRoles([RoleEnum.PERM_ERROR_TRACKING_READ, RoleEnum.UT_CU])],
          parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
          dashboardType: 'deploymentTracking',
        },
      },
      {
        path: 'data-analytics/e311-e312-aec-vehicle-inventory',
        name: types.TEMPLATE_DASHBOARD_DATA_CENTER_E_3_1_1_E_3_1_2_AEC_ERROR_TRACKING,
        redirect: { name: types.DATA_E3_1_1_E3_1_2_AEC_ERROR_TRACKING_DASHBOARD },
        meta: {
          isRedirect: true,
          roles: [() => hasRoles([RoleEnum.PERM_ERROR_TRACKING_READ, RoleEnum.UT_CU])],
          parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
        },
      },
      {
        path: 'data-analytics/e3-12-deployment-tracking',
        name: types.TEMPLATE_DASHBOARD_DATA_CENTER_ORU_1_2_DEPLOYMENT_TRACKING,
        redirect: { name: types.DATA_E3_1_2_DEPLOYMENT_TRACKING_DASHBOARD },
        meta: {
          isRedirect: true,
          roles: [() => hasRoles([RoleEnum.PERM_DEPLOYMENT_TRACKING_READ, RoleEnum.VP_E312, RoleEnum.UT_ORU_NEXT])],
          parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
          dashboardType: 'deploymentTracking',
        },
      },
      {
        path: 'data-analytics/e3-20-developer-dashboard',
        name: types.TEMPLATE_DASHBOARD_DATA_CENTER_E_3_2_0_ORU_DEVELOPER_MODE_ERROR_TRACKING,
        redirect: { name: types.DATA_E3_2_0_ORU_DEVELOPER_DASHBOARD },
        meta: {
          isRedirect: true,
          roles: [() => hasRoles([RoleEnum.PERM_ERROR_TRACKING_READ, RoleEnum.VP_E320])],
          parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
          dashboardType: 'others',
        },
      },
      {
        path: 'software/data-load',
        name: types.TEMPLATE_DASHBOARD_DATA_CENTER_DATA_LOAD_MANAGEMENT_DSH,
        redirect: { name: types.DATA_E3_1_1_DATA_LOAD_DASHBOARD },
        meta: {
          isRedirect: true,
          roles: [RoleEnum.PERM_DATALOAD_READ],
          parent: types.TEMPLATE_DASHBOARD_DATA_CENTER,
          dashboardType: 'others',
        },
      },
    ],
  },
  {
    path: '/archive',
    name: types.TEMPLATE_DASHBOARD_ARCHIVE,
    component: () => import('@/views/DashboardTemplate'),
    props: () => ({
      removeSubNavText: true,
      removeUnderConstruction: true,
    }),
    meta: {
      isMainNavigation: true,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faBoxArchive')).faBoxArchive,
    },
    children: [],
  },
  {
    path: '/parameters',
    name: types.TEMPLATE_DASHBOARD_PARAMETERS,
    component: () => import('@/views/DashboardTemplate'),
    props: () => ({
      removeSubNavText: true,
      removeUnderConstruction: true,
    }),
    meta: {
      isMainNavigation: true,
      icon: async () => (await import('@fortawesome/free-solid-svg-icons/faSliders')).faSliders,
    },
    children: [
      {
        path: 'keycloak',
        name: types.TEMPLATE_DASHBOARD_PARAMETERS_KEYCLOAK,
        component: () => undefined,
        meta: {
          roles: [() => useAuthStore().hasPermissionToSeeKeycloak],
          parent: types.TEMPLATE_DASHBOARD_PARAMETERS,
          overwriteUrl: '/auth/admin/cc/console',
          shouldOpenInNewTab: true,
        },
      },
      {
        path: 'superset',
        name: types.TEMPLATE_DASHBOARD_PARAMETERS_SUPERSET,
        component: () => undefined,
        meta: {
          roles: [() => hasRole(RoleEnum.SUPERSET_ADMIN) || hasRole(RoleEnum.ANALYST)],
          parent: types.TEMPLATE_DASHBOARD_PARAMETERS,
          overwriteUrl: () => `/tokenlogin/?redirect=${location.origin}/superset/welcome`,
          shouldOpenInNewTab: true,
        },
      },
      {
        path: 'storybook',
        name: types.TEMPLATE_DASHBOARD_PARAMETERS_STORYBOOK,
        component: () => undefined,
        meta: {
          roles: [() => isDeploymentStage(window), RoleEnum.BRAND_VW],
          parent: types.TEMPLATE_DASHBOARD_PARAMETERS,
          overwriteUrl: () => '/storybook/index.html',
          shouldOpenInNewTab: true,
        },
      },
    ],
  },
  {
    path: '/no-access',
    name: types.NO_ACCESS,
    component: () => import('@/views/NoAccessView'),
  },
];

export default routes;
