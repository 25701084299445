<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<template>
  <Tooltip :content="titleComputed" :disabled="!titleComputed" :placement="placement" :show-after="showAfter">
    <span v-bind="$attrs" class="status-tag" :class="[{ small, large }, typeComputed]" data-test="status-tag">
      <slot>{{ labelComputed }}</slot>
    </span>
  </Tooltip>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Tooltip from '@/components/Tooltip';

export enum TagTypeEnum {
  NEUTRAL = 'NEUTRAL',
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
}
export type TagType = TagTypeEnum | string;

export default defineComponent({
  name: 'StatusTag',
  components: { Tooltip },

  inheritAttrs: false,

  props: {
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    type: {
      type: String as PropType<TagType>,
      required: false,
      default: '',
    },
    small: {
      type: Boolean,
      required: false,
    },
    large: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: undefined,
    },
    placement: {
      type: String as PropType<import('@popperjs/core').Placement>,
      required: false,
      default: 'right',
    },
    showAfter: {
      type: Number,
      required: false,
      default: undefined,
    },
    i18nPrefix: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  computed: {
    labelComputed() {
      if (this.i18nPrefix) {
        if (!this.label) return this.$to(this.i18nPrefix);
        return this.$to(this.label, this.i18nPrefix);
      }
      return this.label;
    },
    titleComputed() {
      return this.title || (this.i18nPrefix ? this.$tt(this.i18nPrefix + (this.label || '')) : undefined);
    },
    typeComputed() {
      if (this.type) return this.type;
      switch (this.label) {
        case 'ACTION_REQUIRED':
        case 'ACTIVE':
        case 'NOT_STARTED_YET':
        case 'CREATED':
        case 'REQUESTED':
        case 'ACCEPTED':
        case 'VEHICLE_LIST_ADDED':
        case 'ROLLOUT_READY':
          return TagTypeEnum.ACTION_REQUIRED;
        case 'INACTIVE':
        case 'CANCELED':
        case 'REJECTED':
        case 'Rejected':
        case 'ROLLOUT_FAILED':
        case 'Failed':
        case 'CAMPAIGN_CREATION_FAILED':
          return TagTypeEnum.NEGATIVE;
        case 'IN_PROGRESS':
        case 'PROGRESS':
        case 'In Progress':
        case 'PENDING':
        case 'PLANNED':
        case 'READY_TO_ROLLOUT':
        case 'IN_PREPARATION':
        case 'EDITING':
        case 'TRANSLATION':
        case 'PUBLISHED':
        case 'ROLLOUT_INITIATED':
          return TagTypeEnum.IN_PROGRESS;
        case 'APPROVED':
        case 'READY_FOR_DEPLOYMENT':
        case 'ROLLOUT':
        case 'FINISHED':
        case 'COMPLETED':
        case 'Confirmed':
        case 'READY':
        case 'ROLLOUT_SUCCESSFUL':
        case 'ENABLEMENT_SUCCESSFUL':
        case 'Successful':
          return TagTypeEnum.POSITIVE;
        case 'Open':
        case 'ROLLOUT_ABORTED':
          return TagTypeEnum.NEUTRAL;
      }
      return TagTypeEnum.NEUTRAL;
    },
  },
});
</script>

<style lang="scss" scoped>
@use '@/assets/theme/colors';
@use '@/assets/theme/variables';

.status-tag {
  display: inline-flex;
  white-space: nowrap;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 12px;
  padding: 0 12px;
  font-family: variables.$dlcm-font-regular;
  font-weight: normal;
  font-size: 14px;

  &.small {
    padding: 0 8px;
    height: 16px;
    border-radius: 9px;
    font-family: variables.$dlcm-font-medium;
    font-size: 12px;
    font-weight: 500;
  }

  &.large {
    padding: 0 16px;
    height: 32px;
    border-radius: 16px;
    font-size: 16px;
  }

  &.NEUTRAL {
    color: colors.$neutral-700;
    background-color: colors.$neutral-100;
  }

  &.POSITIVE {
    color: colors.$positive-text;
    background-color: colors.$positive;
  }

  &.NEGATIVE {
    color: colors.$negative-text;
    background-color: colors.$negative;
  }

  &.IN_PROGRESS {
    color: colors.$in-progress-text;
    background-color: colors.$in-progress;
  }

  &.ACTION_REQUIRED {
    color: colors.$action-required-text;
    background-color: colors.$action-required;
  }
}
</style>
