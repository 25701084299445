/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';
import { BaseCampaignDataLoad } from '@/modules/campaign_management/models/CommonCampaignModels';

export const ZodDataLoadManagementState = z.enum([
  'CREATED',
  'PLANNED',
  'PAUSED',
  'ACTIVE',
  'STOPPED',
  'CANCELED',
  'DELETED',
  'UNKNOWN',
]);
export const DataLoadManagementState = ZodDataLoadManagementState.enum;
export type DataLoadManagementState = z.infer<typeof ZodDataLoadManagementState>;

export type DataLoadFormType = {
  type: string;
  brand: string;
  title: string;
  dataPackageSize: number;
  campaignOwner: string;
  campaignOwnerSelf: boolean;
  recallId: string | null;
  updateType: string | null;
  customerImpact: boolean | null;
};
export const DataLoadBatchType = z.object({
  vehicleVolume: z.number(),
  batchStart: z.string(),
  batchId: z.number(),
  criterion: z.string().nullable(),
  progress: z.number().nullable(),
  state: ZodDataLoadManagementState,
  priorityIndex: z.number().nullable().optional(),
  updateType: z.string().nullable().optional(),
  customerImpact: z.boolean().nullable().optional(),
});

export type DataLoadBatchType = z.infer<typeof DataLoadBatchType>;

export const DataLoadCampaign = BaseCampaignDataLoad.extend({
  dataPackageSize: z.number(),
  enrollmentRate: z.number(),
  campaignOwner: z.string(),
  campaignOwnerSelf: z.boolean(),
  recallId: z.string().nullable(),
  progress: z.number().nullable(),
  priorityIndex: z.number().nullable().optional(),
  updateType: z.string().nullable().optional(),
  customerImpact: z.boolean().nullable().optional(),
});
export type DataLoadCampaign = z.infer<typeof DataLoadCampaign>;

export type DataLoadBatchByteSize = {
  size: number;
  unit: string;
};

export enum DataLoadUnitLabels {
  MB = 'MB',
  GB = 'GB',
  TB = 'TB',
}

export const EvaluationResultEnum = z.enum(['PLAN', 'CORRECT', 'REJECT']);
export const EvaluationResult = EvaluationResultEnum.enum;
export type EvaluationResult = z.infer<typeof EvaluationResultEnum>;

export const Evaluation = z.object({
  lastEvaluated: z.string().optional(),
  state: EvaluationResultEnum.optional(),
  canBeEvaluated: z.boolean(),
});
export type Evaluation = z.infer<typeof Evaluation>;
